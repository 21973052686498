import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import Button from "../../components/Common/Button";
import { useUser } from "../../context/userContext";
import { Toast } from "../../components/Common/Toast";
import BackCoverPages from "./BackCoverPages";
import {
  AGE_0_2,
  AGE_3_7,
  AGE_8_12,
  DEDUCT_CREDITS_PER_IMAGE,
  HTTP_STATUS_200,
  LOCAL_STORAGE_EDIT_MODE,
  MID_JOURNEY_MODE_RELAX,
  PROMO_PLAN,
} from "../../helper/Constant";
import { callAPI } from "../../services/apiService";
import { convertSecondsToMinutes, sortBookPages } from "./builderHelpers";
import FrontCoverPages from "./FrontCoverPages";
import EditImageTextIllustration from "./EditImageTextIllustration";
import ChooseImageFromHistory from "./ImageHistory";
import { EditStorySummary } from "./EditStorySummary";
import { scrollToArea } from "../../helper/helper";
import { useApp } from "../../context/appContext";
import { useStory } from "../../context/storyContext";
import Modal from "../../components/Common/Modal";

const StoryPages = ({
  bookData,
  openModal,
  edit = false,
  loaderImageGeneration = null,
  setShowImageHistory = null,
  mode,
  // userCreditBalance,
  // setUserCreditBalance,
  setShowLowCreditModal,
  setShowBookCover,
  showBookCover,
  selectedCoverUrl,
  displayBookPages,
  setStateData,
  stateData,
  summaryModal,
  setSummaryModal,
  setShowStoryForm,
  setCurrentStep,
  setimageCharacterPrompt,
  imageCharacterPrompt,
  showEditTextIllustration,
  setShowEditTextIllustration,
  editing,
  setEditing,
  setShowCharacterLibrary,
}) => {
  const navigate = useNavigate();
  const { storyData, setStoryData } = useStory();
  const { user } = useUser();
  const targetRefs = useRef({});
  // const [showEditTextIllustration, setShowEditTextIllustration] = useState(false);
  // const [editing, setEditing] = useState({
  //   bookId: null,
  //   pageIndex: null,
  //   pageData: null,
  //   type: null,
  //   index: null,
  // });
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showRelaxModeModal, setShowRelaxModeModal]=useState(false)
  const [pages, setPages] = useState([]);
  const [isUpdateImage, setIsUpdateImage] = useState({});
  const [updateImgIndex, setUpdateImgIndex] = useState(null);

  // const [summaryModal, setSummaryModal] = useState(false);
  const summaryId = 0;
  const { setBookDataModified } = useApp();
  const closeSummaryModal = () => {
    setSummaryModal(false);
    scrollToArea(targetRefs, "edit-summary");
  };

  const [mainImages, setMainImages] = useState(Array(bookData.pages.length).fill(""));

  // Function to handle image click for a specific card
  const handleImageClick = async (imageURL, imageURLWebp, index, pageIndex) => {
    // console.log("222 handle ImageClick===", imageURL, imageURLWebp, index, pageIndex);
    setUpdateImgIndex(pageIndex);
    setIsUpdateImage({ ...isUpdateImage, [pageIndex]: true });
    const updatedMainImages = [...mainImages];
    updatedMainImages[index] = `url(${imageURL})`;
    setMainImages(updatedMainImages);

    const switchImage = await callAPI("api/book-update/switch-page-image", {
      bookId: bookData?.bookId,
      pageIndex: ++index,
      imageURL,
      imageURLWebp,
    });
    if (switchImage?.status !== HTTP_STATUS_200) Toast("Image Switch Failed", true);
    if (localStorage.getItem(LOCAL_STORAGE_EDIT_MODE) === "yes") {
      setBookDataModified(true);
    }
    setIsUpdateImage({ ...isUpdateImage, [pageIndex]: false });
    setUpdateImgIndex(null);
  };
  useEffect(() => {
    sortBookPages(bookData, setPages);
  }, [bookData]);

  useEffect(() => {
    if (storyData?.illustrationCharacter?.index) {
      setEditing({
        ...editing,
        bookId: bookData?.bookId,
        pageIndex: storyData?.illustrationCharacter?.pageIndex,
        pageData: bookData?.pages[storyData?.illustrationCharacter?.pageIndex],
        type: "editPrompt",
        index: storyData?.illustrationCharacter?.index,
      });
      setShowEditTextIllustration(true);
    }
  }, [bookData, storyData]);

  let totalPages = bookData?.pages;
  totalPages = Object.keys(totalPages).length;

  // console.log("bookdata in storypages", bookData);

  const RegenerateImage = (page, pageIndex, index) => {
    return (
      <div className=" w-full  flex gap-1 mx-auto justify-center">
        {page?.queuePosition && page?.queueEstimatedTime ? (
          <div className="w-full bg-storyerror flex gap-1 justify-between items-center text-white font-sans rounded-md p-2 text-xs my-4 px-2 h-8">
            <span>
              Queue position: <strong className="ml-2">{page.queuePosition}</strong>
            </span>{" "}
            <span>
              {" "}
              Estimate Time:
              <strong className="ml-2">{convertSecondsToMinutes(page?.queueEstimatedTime)}</strong>
            </span>
          </div>
        ) : (
          <Button
            title={
              (loaderImageGeneration && loaderImageGeneration[pageIndex]) ||
              (page?.progress && page?.progress >= 0) ? (
                <>
                  {page?.progress >= 0 && <span>Processing </span>}
                  <Loader loader={loaderImageGeneration[pageIndex] || page?.progress >= 0} />
                  {/* {page?.progress && `${page?.progress}%`} */}
                  {page?.progress && page?.progress > 0 && `${page?.progress}%`}
                </>
              ) : page?.progress === null ? (
                "Error! Regenerate Image"
              ) : (
                <>
                  <span>Processing </span>
                  <Loader loader={loaderImageGeneration[pageIndex] || page?.progress >= 0} />
                </>
              )
            }
            disabled={(loaderImageGeneration && loaderImageGeneration[pageIndex]) || page?.progress !== null}
            className="bg-storyerror flex gap-1 items-center text-white font-sans rounded-md p-2 text-xs my-4 px-2 h-8"
            onclick={() => {
              console.log(
                "Error! Regenerate Image clicked",
                storyData?.creditsPerBookLimit,
                bookData?.creditsPerBookLimit
              );
              // if (parseInt(userCreditBalance) >= parseInt(DEDUCT_CREDITS_PER_IMAGE)) {
              // if (parseInt(bookData?.creditsPerBookLimit) >= parseInt(DEDUCT_CREDITS_PER_IMAGE)) {
              if (
                parseInt(storyData?.creditsPerBookLimit) >= parseInt(DEDUCT_CREDITS_PER_IMAGE) &&
                parseInt(bookData?.creditsPerBookLimit) >= parseInt(DEDUCT_CREDITS_PER_IMAGE)
              ) {
                setStoryData({
                  ...storyData,
                  creditsPerBookLimit: parseInt(storyData?.creditsPerBookLimit) - DEDUCT_CREDITS_PER_IMAGE,
                });
                // openModal(pageIndex, page, "editPrompt", index * 2 + 2);
                setEditing({
                  ...editing,
                  bookId: bookData?.bookId,
                  pageIndex,
                  pageData: page,
                  type: "editPrompt",
                  index: index * 2 + 2,
                });
                setShowEditTextIllustration(true);
              } else {
                Toast("Additional Image Credits Needed", true);
                // navigate("/ImageTokens");
              }
            }}
            // tooltip={`Consume ${DEDUCT_CREDITS_PER_IMAGE} Image Credits`}
          />
        )}
      </div>
    );
  };




  const GeneralProcessButtons = (page, pageIndex, index) => {
    return (
      <div className=" w-full flex gap-1 mx-auto justify-center">
        {page?.queuePosition && page?.queueEstimatedTime ? (
          <div className="w-full bg-storygreen flex gap-1 justify-between items-center text-white font-sans rounded-md p-2 text-xs my-4 px-2 h-8">
            <span>
              Queue position: <strong className="ml-2">{page.queuePosition}</strong>
            </span>{" "}
            <span>
              {" "}
              Estimate Time:
              <strong className="ml-2">{convertSecondsToMinutes(page?.queueEstimatedTime)}</strong>
            </span>
          </div>
        ) : (
          <div className=" w-full flex gap-1 mx-auto justify-center">
            <Button
              title={
                (loaderImageGeneration && loaderImageGeneration[pageIndex]) ||
                (page?.progress && page?.progress >= 0) ? (
                  <>
                    {page?.progress >= 0 && <span>Processing </span>}
                    <Loader loader={loaderImageGeneration[pageIndex] || page?.progress >= 0} />
                    {/* {page?.progress && `${page?.progress}%`} */}
                    {page?.progress && page?.progress > 0 && `${page?.progress}%`}
                  </>
                ) : page?.progress === null ? (
                  "Create New Image"
                ) : (
                  <>
                    <span>Processing </span>
                    <Loader loader={loaderImageGeneration[pageIndex] || page?.progress >= 0} />
                  </>
                )
              }
              // tooltip={` Consume ${DEDUCT_CREDITS_PER_IMAGE} Image Credits`}
              className="bg-storygreen flex gap-1 items-center text-white  font-sans rounded-md p-2 text-xs my-4 px-2 h-8"
              onclick={() => {
                // console.log("Create New Image clicked", storyData, bookData);
                if (
                  (parseInt(storyData?.creditsPerBookLimit) >= parseInt(DEDUCT_CREDITS_PER_IMAGE) &&
                    parseInt(bookData?.creditsPerBookLimit) >= parseInt(DEDUCT_CREDITS_PER_IMAGE)) ||
                  bookData?.modeIllustrationGeneration === MID_JOURNEY_MODE_RELAX
                  // &&
                  // user?.plan?.name !== PROMO_PLAN
                ) {
                  setEditing({
                    ...editing,
                    bookId: bookData?.bookId,
                    pageIndex,
                    pageData: page,
                    type: "editPrompt",
                    index: index * 2 + 2,
                  });
                  setShowEditTextIllustration(true);
                }
                // else if (user?.plan?.name === PROMO_PLAN) {
                //   setShowPlanModal(true);
                // }
                else if (
                  user?.plan?.name !== PROMO_PLAN &&
                  bookData?.modeIllustrationGeneration !== MID_JOURNEY_MODE_RELAX
                ) {
                  // Toast("Additional Image Credits Needed", true);
                  // console.log("clickedddd");
                  setShowRelaxModeModal(true);
                }
              }}
              disabled={page?.progress !== null}
            />

            {bookData?.imageLibrary?.length > 0 && (
              <Button
                title="Image Library"
                className="bg-storygreen flex gap-1 items-center text-white font-sans rounded-md p-1 text-xs my-4 px-2 h-8"
                onclick={() => {
                  setStateData({ ...stateData, pageIndex, showImageLibrary: true });
                  setShowImageHistory(pageIndex);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  };
  const showCloseButtonFunction = () => {
    setShowPlanModal(false);
  };

    const showCloseButtonRelaxModalFunction = () => {
      setShowRelaxModeModal(false);
    };
  // console.log("bokkData", bookData);


  // console.log("Storydata in story pages========>>", storyData,"bookdata in story pages==========>>>>", bookData);



  return (
    <>
      {summaryModal ? (
        <EditStorySummary
          summary={bookData?.summary}
          type={mode}
          closeSummaryModal={closeSummaryModal}
          displayBookPages={displayBookPages}
          targetRefs={targetRefs}
          summaryId={summaryId}
        />
      ) : (
        <>
          {showEditTextIllustration ? (
            <EditImageTextIllustration
              bookId={editing.bookId}
              pageIndex={editing.pageIndex}
              pageData={editing.pageData}
              type={editing.type}
              index={editing.index}
              setShowEditTextIllustration={setShowEditTextIllustration}
              // userCreditBalance={userCreditBalance}
              // setUserCreditBalance={setUserCreditBalance}
              setShowLowCreditModal={setShowLowCreditModal}
              displayBookPages={displayBookPages}
              setStateData={setStateData}
              stateData={stateData}
              targetRefs={targetRefs}
              setShowStoryForm={setShowStoryForm}
              setCurrentStep={setCurrentStep}
              setimageCharacterPrompt={setimageCharacterPrompt}
              editing={editing}
              setShowCharacterLibrary={setShowCharacterLibrary}
            />
          ) : stateData?.showImageLibrary ? (
            <ChooseImageFromHistory
              bookData={bookData}
              pageIndex={stateData?.pageIndex}
              setShowImageHistory={setShowImageHistory}
              displayBookPages={displayBookPages}
              setStateData={setStateData}
              stateData={stateData}
              targetRefs={targetRefs}
            />
          ) : (
            <>
              {showPlanModal && (
                <Modal
                  title="Plan Purchase"
                  description="Please choose a plan to continue working on your book!."
                  doProcess={() => navigate("/myPlans")}
                  showCloseButtonFunction={showCloseButtonFunction}
                  showCloseButton={true}
                />
              )}
              {showRelaxModeModal && (
                <Modal
                  title="High Speed Image Renewal Alert"
                  description="Illustration credits exhausted, continue or re-store image renewal speed!."
                  doProcess={() => navigate("/ImageTokens")}
                  submitButtonTitle="re-store image renewal speed"
                  // showCloseButtonFunction={showCloseButtonRelaxModalFunction}
                  showCloseButton={false}
                  showFreeBookViaRelaxMode={true}
                />
              )}
              <FrontCoverPages
                bookData={bookData}
                editCover={true}
                setShowBookCover={setShowBookCover}
                showBookCover={showBookCover}
                selectedCoverUrl={selectedCoverUrl}
                displayBookPages={displayBookPages}
                mode={mode}
                setStateData={setStateData}
                stateData={stateData}
              />
              <div className={` ${mode === "Page" ? "  " : "grid grid-cols-1 lg:grid-cols-2 gap-4"} `}>
                {pages &&
                  pages?.length > 0 &&
                  pages?.map(([pageIndex, page], index) => {
                    return (
                      <div
                        id={pageIndex}
                        key={pageIndex}
                        ref={(ref) => (targetRefs.current[pageIndex] = ref)}
                        className={` ${
                          mode === "Page"
                            ? " border-none "
                            : "m-2 md:flex md:justify-center gap-4 p-4 xs:flex-wrap "
                        }  rounded border  `}
                      >
                        <div className="flex flex-col items-center justify-between rounded-lg ">
                          <div
                            className={`  ${
                              mode === "Page"
                                ? " h-[350px] w-[350px]  md:w-[600px] md:h-[600px] "
                                : " w-72 h-72"
                            }  text-2xl  overflow-auto px-8 flex flex-col items-center justify-center text-center font-serif bg-white rounded-lg relative`}
                            style={{
                              backgroundImage: `url("/paper.jpg")`,
                              backgroundSize: "cover",
                            }}
                          >
                            <h3
                              className={`font-bold text-left font-poppins ${
                                bookData?.childAge === AGE_0_2
                                  ? "text-2xl"
                                  : bookData?.childAge === AGE_3_7
                                  ? "text-xl"
                                  : bookData?.childAge === AGE_8_12
                                  ? "text-lg"
                                  : "text-sm"
                              } ${
                                mode === "Grid"
                                  ? "h-[13.5rem] overflow-y-auto flex items-start"
                                  : // : mode === "Page"
                                    // ? "h-[32rem] overflow-y-auto flex items-start"
                                    ""
                              }`}
                            >
                              {page?.text}
                            </h3>
                            <div className="page-number mb-2 w-full flex justify-center absolute bottom-0 left-1/2 transform -translate-x-1/2">
                              <div
                                className=" text-base w-12 h-6 flex justify-center items-center font-bold text-storyblue"
                                style={{ fontFamily: "system-ui" }}
                              >
                                {index * 2 + 5}
                              </div>
                            </div>
                          </div>
                          {edit && (
                            <Button
                              title="Edit Text"
                              //onclick={() => openModal(pageIndex, page, "editText", index * 2 + 1)}
                              onclick={() => {
                                setEditing({
                                  ...editing,
                                  bookId: bookData?.bookId,
                                  pageIndex,
                                  pageData: page,
                                  type: "editText",
                                  index: index * 2 + 1,
                                });
                                setShowEditTextIllustration(true);
                              }}
                              className="bg-storygreen flex  text-white  font-sans rounded-md p-2 text-xs m-4  h-8 w-[120px] justify-center"
                            />
                          )}
                        </div>
                        <div className=" flex flex-col items-center">
                          <div className="flex flex-col justify-center items-center">
                            <div
                              className={` ${
                                mode === "Page"
                                  ? ` h-[350px] w-[350px]  md:w-[600px] md:h-[600px]`
                                  : " w-72 h-72"
                              } rounded-lg mx-auto bg-gray-200 flex flex-col items-center justify-between relative`}
                              style={{
                                backgroundImage: `url('${
                                  page.imageURLwebp || page.imageURL || "/pattern.png"
                                }')`,
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            >
                              {((!page.imageURLwebp && !page.imageURL) ||
                                (isUpdateImage && updateImgIndex == pageIndex)) &&
                                user?.plan?.name !== PROMO_PLAN && (
                                  <img
                                    src="/loading.gif"
                                    className={`h-36 w-36  ${mode === "Page" ? " mt-[14rem] " : "mt-[5rem]"}`}
                                    alt="Loading"
                                  />
                                )}

                              <div className="page-number mb-2 w-full flex justify-center absolute bottom-0 left-1/2 transform -translate-x-1/2">
                                <div
                                  className=" w-12 h-6 flex justify-center items-center rounded-full font-bold text-base text-white"
                                  style={{
                                    fontFamily: "system-ui",
                                    position: "absolute",
                                    bottom: "0",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                  }}
                                >
                                  {/* {index * 2 + 6}/{bookData?.pageTotal} */}
                                  {index * 2 + 6}
                                </div>
                              </div>
                            </div>
                          </div>
                          {page?.images && (
                            <div className="mt-2 flex ">
                              {page?.images?.map((img) => {
                                return (
                                  <div
                                    disabled={isUpdateImage[pageIndex] ? isUpdateImage[pageIndex] : false}
                                    className={`flex flex-col  gap-1    ${
                                      mode === "Grid" ? "mt-2px" : "mt-1"
                                    }`}
                                  >
                                    <img
                                      src={img.imageURL}
                                      alt="Image 1"
                                      style={{
                                        pointerEvents: isUpdateImage[pageIndex] ? "none" : "auto",
                                      }}
                                      className={`  ${
                                        isUpdateImage[pageIndex] ? "opacity-50" : "opacity-100"
                                      }  ${
                                        mode === "Page"
                                          ? "w-[80px] h-[80px] md:w-[145px] md:h-[145px]"
                                          : " w-[60px] h-[60px]"
                                      }  rounded cursor-pointer border-white border-rounded border-2`}
                                      onClick={() => {
                                        handleImageClick(img.imageURL, img.imageURLwebp, index, pageIndex);
                                        // setUpdateImgIndex(pageIndex);
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          {page?.error
                            ? RegenerateImage(page, pageIndex, index)
                            : edit && GeneralProcessButtons(page, pageIndex, index)}
                        </div>
                      </div>
                    );
                  })}
                <div className="m-0 md:m-2">
                  <BackCoverPages
                    bookData={bookData}
                    mode={mode}
                    displayBookPages={displayBookPages}
                    setSummaryModal={setSummaryModal}
                    targetRefs={targetRefs}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default StoryPages;
