import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faTrashCan,
  faAngleRight,
  faBookOpen,
  faCirclePlus,
  faCircleMinus,
  faSackDollar,
  faEdit,
  faBan,
  faCircleCheck,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import Dialogs from "../../components/Common/Dialog";
import SearchInput from "../../components/Common/SearchInput";
import { Link } from "react-router-dom";
import { CreditForm } from "./Forms/CreditForm";
import { debounce } from "../../helper/Debounce";
import PaginationButtons from "../../components/Common/Pagination";
import { convertTimestampToDateTime } from "../../helper/helper";
import BookPage from "../BookPage/BookPage";
import FlipBook from "../BookFlip/BookFlip";
import {
  HTTP_STATUS_200,
  ROLE_SUPERADMIN,
  ROLE_USER,
  USER_STATUS_ACTIVE,
  USER_STATUS_INACTIVE,
} from "../../helper/Constant";
import TableHeader from "../../components/Common/TableHeader";
import UserDetailModal from "./Forms/UserDetailModal";
import { useUser } from "../../context/userContext";
import UserPaymentDetails from "./Tables/UserPaymentDetails";
import { NoRecordFound } from "../../components/Common/NoRecordFound";
import UsersTable from "./customertable";

const Customer = ({ onOptionClick, onSelectUser }) => {
  const { user: loggedinUser } = useUser();
  const PARENT_CALL_CUSTOMER = "customer";
  const PARENT_CALL_BOOK = "book";
  const [parentCall, setParentCall] = useState(PARENT_CALL_CUSTOMER);
  const [loader, setLoader] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [email, setEmail] = useState(null);
  const [viewBooks, setViewBooks] = useState(false);
  const [showCreditForm, setShowCreditForm] = useState(null);
  const [creditDetails, setCreditDetails] = useState(null);
  const [selectedUserInfo, setSelectedUserInfo] = useState(null);
  const [books, setBooks] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const itemsPerPage = 12; // Items per page
  const pageCount = Math.ceil(allUsers?.length / itemsPerPage);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [showBookPage, setShowBookPage] = useState("");
  const [showFlipBook, setShowFlipBook] = useState("");
  const [booksPageNumber, setBooksPageNumber] = useState(1);
  const [totalBooksPages, setTotalBooksPages] = useState(null);
  const [users, setUsers] = useState(allUsers);
  const [isUserDetailModalOpen, setisUserDetailModalOpen] = useState(false);
  const [paymentDetailsModalOpen, setPaymentDetailsModalOpen] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  // const [totalUsers, setTotalUsers] = useState([]);
  const [allUserPlans, setAllUserPlans] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("name");

  useEffect(() => {
    if (!showCreditForm) {
      if (parentCall === PARENT_CALL_CUSTOMER) setViewBooks(false);
      else if (parentCall === PARENT_CALL_BOOK) setViewBooks(true);
    }
  }, [parentCall, showCreditForm]);

  const getAllUsers = async () => {
    try {
      setLoader(true);
      const data = await callAPI(
        `admin/user?pageNumber=${currentPage}&role=${ROLE_USER}&sortBy=${sortBy}&sortByValue=${sortOrder}`
      );
      setAllUsers(data?.users);
      setTotalPages(data?.totalPages);
      setLoader(false);
    } catch {
      setLoader(false);
    }
  };

  const searchUsers = async () => {
    try {
      setSearchLoader(true);
      const data = await callAPI(`admin/user-search?search=${searchText}`);

      setAllUsers(data?.message);
      setSearchLoader(false);
    } catch (error) {
      console.error("Error searching users:", error);
      Toast("Error Searching Users", true);
      setSearchLoader(false);
    }
  };

  const getTotalUsers = async () => {
    try {
      const data = await callAPI(`admin/admin-user-plans`);
      setAllUserPlans(data?.message);
    } catch (error) {
      console.error("Error getting plans:", error);
      Toast("Error get Plans", true);
      setSearchLoader(false);
    }
  };

  const getActiveUsers = async (selectedPlanId) => {
    try {
      const data = await callAPI(`admin/admin-user-plans?id=${selectedPlanId}`);
      // console.log(data?.message?.selectedPlanActiveUsers, "getACtiveusers");
      setAllUserPlans(data?.message);
    } catch (error) {
      console.error("Error getting plans:", error);
      Toast("Error get Plans", true);
      setSearchLoader(false);
    }
  };
  // console.log("State allUserPlans:", allUserPlans?.totalUsers);
  const getUserBooks = async (email) => {
    try {
      if (!email) return;
      setLoader(true);
      const response = await callAPI(`api/booklist?email=${email}&pageNumber=${booksPageNumber}`);
      //console.log(response, "response");
      if (response.status === HTTP_STATUS_200) {
        setTotalBooksPages(response?.totalBooksPages);
        const userBooks = response?.books;
        if (userBooks?.length > 0) {
          setViewBooks(true);
          setBooks(userBooks);
        } else {
          Toast("No Books Found!");
        }
      } else {
        Toast("Something went wrong", true);
      }
      // setViewBooks(true);
      // setBooks(userBooks || []);
      // if (userBooks?.length === 0) Toast("No books found");
      setLoader(false);
    } catch (error) {
      console.error("Error fetching user books:", error);
      setLoader(false);
    }
  };


//on credit modal close clear all data
  useEffect(() => {
    // console.log("useeffect called", showCreditForm, creditDetails, selectedBook);
    if (showCreditForm === false) {
      setCreditDetails(null);
      setSelectedBook(null);
    }
  }, [showCreditForm]);



  useEffect(() => {
    getUserBooks();
    getTotalUsers();
  }, [currentPage]);

  // Handle page change
  const handleBooksPageChange = (page) => {
    if (page > 0 && page <= totalBooksPages) setBooksPageNumber(page);
  };

  const handleDeleteUser = async () => {
    try {
      const response = await callAPI(`admin/user-delete`, {
        email,
      });
      // console.log(response, "res");
      if (response) {
        Toast("User Deleted Successfully");
      }
      getAllUsers();
      setOpenDialog(false);
    } catch (err) {
      Toast("User Delete Failed", true);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [currentPage, sortBy, sortOrder]);

  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  const updateUserStatus = async (email, newStatus) => {
    try {
      const response = await callAPI(`admin/user-update-status`, {
        email: email,
        status: newStatus,
      });
      // console.log("response", response);
      if (response?.status === HTTP_STATUS_200) Toast("Status Updated Successfully");
      else Toast(response?.message || "Failed To Update Status", true);
    } catch (error) {
      Toast("Failed To Update Status", true);
    }
  };

  const handleStatusChange = async (email, newStatus) => {
    try {
      await updateUserStatus(email, newStatus);
      getAllUsers();
      const updatedUsers = allUsers.map((user) => {
        if (user.email === email) {
          return { ...user, status: newStatus };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Update User Status Failed", error);
    }
  };

  const handleEditCredits = (userId) => {
    if (selectedBook) {
      setCreditDetails(
        parseInt(selectedBook?.creditsPerBookLimit || 0) - parseInt(selectedBook?.booksCreatedCounter || 0)
      );
    } else {
      const userInfo = allUsers.filter((val) => val.id === userId);
      setCreditDetails(
        parseInt(userInfo[0]?.booksCreateLimit || 0) - parseInt(userInfo[0]?.booksCreatedCounter || 0)
      );
    }
    setShowCreditForm(userId);
  };

  const editUser = () => {
    setisUserDetailModalOpen(true);
  };
  const closeUserDetailModal = () => {
    setisUserDetailModalOpen(false);
  };

  const closePaymentDetailsModal = () => {
    setPaymentDetailsModalOpen(false);
  };

  // Debounce
  const handleSearchInputChange = (value) => {
    setSearchText(value);
  };

  const debouncedSearchUsers = debounce(searchUsers, 300);

  useEffect(() => {
    if (searchText && searchText.length >= 3) debouncedSearchUsers();
    else if (!searchText) getAllUsers();
  }, [searchText]);

  // Image Tracker
  const [showAllPagesMap, setShowAllPagesMap] = useState({});
  const toggleShowAllPages = (bookId) => {
    setShowAllPagesMap((prevState) => ({
      ...prevState,
      [bookId]: !prevState[bookId],
    }));
  };

  const renderImageTracker = (book) => {
    const showAllPages = showAllPagesMap[book.id];
    // const totalPageKeys = Object.keys(book?.pages);
    const totalPageKeys = book?.pages ? Object.keys(book.pages) : [];

    const initialPageCount = totalPageKeys.length < 7 ? totalPageKeys.length : 7; // Or dynamically determine the number of pages
    const displayPageCount = showAllPages ? totalPageKeys?.length : initialPageCount;
    let cells = [];
    for (let i = 1; i <= displayPageCount; i++) {
      const pageKey = `page${i}`;
      const pageData = book.pages[pageKey];
      const pageHasImage = pageData && pageData?.imageURL;
      const cellClass = pageHasImage ? "bg-storyblue" : "bg-gray-300";
      const textColor = pageHasImage ? "text-white" : "text-gray-700";
      cells.push(
        <div
          key={i}
          className={`h-8 w-full ${cellClass} ${textColor}  m-0.5 flex justify-center items-center text-xs cursor-pointer`}
          // onClick={() => openModal(book.id, `page${i}`, book.pages[`page${i}`])}
        >
          {i}
        </div>
      );
    }
    const toggleButton = (
      <button
        className="text-sm text-center flex justify-center items-center  text-storygreen"
        onClick={() => toggleShowAllPages(book.id)}
      >
        {showAllPages ? (
          <FontAwesomeIcon
            icon={faCircleMinus}
            className="cursor-pointer text-xl text-storygreen"
            title="less"
          />
        ) : (
          <FontAwesomeIcon
            icon={faCirclePlus}
            className="cursor-pointer text-xl text-storygreen"
            title="more"
          />
        )}
      </button>
    );
    return (
      <div className="grid grid-cols-8 gap-1 w-full">
        {cells} {totalPageKeys.length > initialPageCount && toggleButton}
      </div>
    );
  };

  useEffect(() => {
    handleEditCredits(selectedUserInfo?.id);
  }, [selectedBook]);

  return (
    <div className="p-0 md:p-4">
      {loader ? (
        <div className="w-full  h-80 flex justify-center items-center">
          <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
        </div>
      ) : viewBooks && books?.length > 0 ? (
        <div className="py-8 mx-auto">
          {/* className="max-w-7xl py-8 mx-auto" */}
          {/* {selectedUserInfo && (
              <div>
                <h1 className="text-lg font-bold">Email: {selectedUserInfo?.email}</h1>
                <h1 className="text-lg font-bold">
                  Name: {selectedUserInfo?.firstName} {selectedUserInfo?.lastName}
                </h1>
              </div>
            )} */}
          <div className="mb-4 text-left">
            {/* {showBookPage ? ( */}
            <button
              className="w-20 flex justify-center bg-storygreen text-white font-bold rounded-md text-base p-2"
              onClick={() => {
                showBookPage
                  ? setShowBookPage(null)
                  : showFlipBook
                  ? setShowFlipBook(null)
                  : setViewBooks(false);
              }}
            >
              Back
            </button>
            {/* ) : showFlipBook ? (
                <button
                  className="w-20 flex justify-center bg-storygreen text-white font-bold rounded-md text-base p-2 ml-2"
                  onClick={() => {
                    setShowFlipBook(null);
                  }}
                >
                  Back
                </button>
              ) : (
                <button
                  className="w-20 flex justify-center bg-storygreen text-white font-bold rounded-md text-base p-2 ml-2"
                  onClick={() => {
                    setViewBooks(false);
                  }}
                >
                  Back
                </button>
              )} */}
          </div>
          {selectedUserInfo && (
            <div className="flex flex-col justify-center bg-white rounded-lg p-2 my-2">
              <h1 className="text-lg text-storyblue font-medium">Email: {selectedUserInfo?.email}</h1>
              <h1 className="text-lg text-storyblue font-medium">
                Name: {selectedUserInfo?.firstName} {selectedUserInfo?.lastName}
              </h1>
            </div>
          )}
          {/* <div className="mb-4 text-left">
              <button
                className="w-20 flex justify-center bg-storygreen text-white font-bold rounded-md text-base p-2 ml-4"
                onClick={() => setViewBooks(false)}
              >
                Back
              </button>
            </div> */}

          {!showBookPage && !showFlipBook && books?.length > 0 ? (
            <div>
              <div className="h-[35rem] w-full rounded-md flex justify-center overflow-y-scroll">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {books &&
                    books.map((book, index) => (
                      <div
                        key={book?.id}
                        className="p-2 book-item font-sans text-white bg-storytan uppercase text-2xl font-bold max-w-[19rem] rounded-md mx-auto mb-2"
                      >
                        <div className="flex justify-center">
                          <img
                            src={book?.coverURL || "bookdraft.png"}
                            alt={`${book?.title} cover`}
                            className="h-[18rem] w-[18rem] object-cover rounded-md shadow-xl mb-2"
                          />
                        </div>
                        {/* <div className="text-center my-2">
                          <div className="image-tracker">{renderImageTracker(book)}</div>
                        </div> */}
                        <Link
                          to={`/books/${book?.id}`}
                          className="text-storytan text-sm h-10 flex items-center justify-center rounded-md bg-storyblue shadow-xl leading-none font-bold font-serif block p-4"
                        >
                          {book.title || "Draft"}
                        </Link>
                        <p className="text-[11px] text-storyblue capitalize">
                          Book Id: {book?.bookId ? book?.bookId : "Book Id Missing"}
                        </p>
                        {/* <div className="grid grid-cols-2 gap-2">
                        <Link to={`/books/${book.id}`}>
                          <div className="w-full h-8 flex justify-center items-center pointer-grab text-white text-xs rounded-md bg-storygreen shadow-xl leading-none font-bold p-2 capitalize">
                            Page View
                          </div>
                        </Link>
                        <Link to={`/bookflip/${book.id}`}>
                          <div className="w-full h-8 flex justify-center items-center text-white text-xs bg-storygreen rounded-md shadow-xl leading-none font-bold p-2 capitalize">
                            FlipBook
                          </div>
                        </Link>
                      </div> */}
                        <div className="grid grid-cols-2 gap-2">
                          <button
                            onClick={() => setShowBookPage(book.bookId)}
                            className="w-full h-8 flex justify-center items-center pointer-grab text-white text-xs rounded-md bg-storygreen shadow-xl leading-none font-bold p-2 capitalize"
                          >
                            Page View
                          </button>
                          <button
                            onClick={() => setShowFlipBook(book.id)}
                            className="w-full h-8 flex justify-center items-center text-white text-xs bg-storygreen rounded-md shadow-xl leading-none font-bold p-2 capitalize"
                          >
                            FlipBook
                          </button>
                          <button
                            onClick={() => {
                              setViewBooks(false);
                              setParentCall(PARENT_CALL_BOOK);
                              setSelectedBook(book);
                              // handleEditCredits(selectedUserInfo?.id);
                            }}
                            className="w-full h-8 flex justify-center items-center text-white text-xs bg-storygreen rounded-md shadow-xl leading-none font-bold p-2 capitalize"
                          >
                            Add Image Credits
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="flex justify-end mt-4 gap-2">
                {totalBooksPages > 1 && (
                  <PaginationButtons
                    totalPages={totalBooksPages}
                    currentPage={currentPage}
                    handlePageChange={handleBooksPageChange}
                  />
                )}
              </div>
            </div>
          ) : showBookPage ? (
            <BookPage bookIdAdmin={showBookPage} />
          ) : showFlipBook ? (
            <FlipBook bookIdAdmin={showFlipBook} />
          ) : (
            ""
          )}
        </div>
      ) : showCreditForm ? (
        <CreditForm
          showCreditForm={showCreditForm}
          setShowCreditForm={setShowCreditForm}
          getAllUsers={getAllUsers}
          getUserBooks={getUserBooks}
          creditDetails={creditDetails}
          book={selectedBook}
        />
      ) : (
        <>
          {!isUserDetailModalOpen && !paymentDetailsModalOpen && (
            <>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-6">
                {allUserPlans ? (
                  <div className="flex flex-col sm:flex-row justify-start gap-4 sm:gap-6">
                    <p className="text-sm sm:text-base text-start">
                      Total Customers: <strong>{allUserPlans?.totalUsers}</strong>
                    </p>
                    <p className="text-sm sm:text-base">
                      Active Customers: <strong>{allUserPlans?.selectedPlanActiveUsers}</strong>
                    </p>
                  </div>
                ) : (
                  <p className="text-sm sm:text-base">No customers found.</p>
                )}

                <div className="flex items-center">
                  <label htmlFor="plans" className="text-sm sm:text-base mr-2">
                    Select Plan:
                  </label>
                  <select
                    id="plans"
                    className="p-2 border rounded-md text-sm sm:text-base"
                    onChange={(e) => {
                      // Handle plan selection change
                      const selectedPlan = e.target.value;
                      // console.log("valueeee", selectedPlan);
                      // Find the plan object based on the selected ID
                      // const selectedPlan = allUserPlans?.plans?.find(
                      //   (plan) => plan?.name === selectedPlanName
                      // );
                      // const selectedPlanName = selectedPlan
                      getActiveUsers(selectedPlan);

                      // Perform action based on selectedPlan
                      // console.log(selectedPlanName);
                    }}
                  >
                    <option value="" disabled selected>
                      Select a Plan
                    </option>
                    <option value="">All Plans</option>
                    {allUserPlans.plans && allUserPlans.plans.length > 0 ? (
                      allUserPlans.plans.map((plan, index) => (
                        <option key={index} value={plan.id}>
                          {plan.name}
                        </option>
                      ))
                    ) : (
                      <option>No plans available</option>
                    )}
                  </select>
                </div>

                <div className="float-right mb-2 w-full md:w-[10rem]">
                  <SearchInput
                    placeholder={"Search Customer"}
                    onChange={(e) => handleSearchInputChange(e?.target?.value)}
                    className={"w-full"}
                  />
                </div>
              </div>
              {searchLoader ? (
                <div className="w-full h-80 flex justify-center items-center">
                  <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
                </div>
              ) : (
                <>
                  <UsersTable
                    allUsers={allUsers}
                    onOptionClick={onOptionClick}
                    onSelectUser={onSelectUser}
                    handleStatusChange={handleStatusChange}
                    loggedinUser={loggedinUser}
                    handleEditCredits={handleEditCredits}
                    setParentCall={setParentCall}
                    PARENT_CALL_CUSTOMER={PARENT_CALL_CUSTOMER}
                    getUserBooks={getUserBooks}
                    setSelectedUserInfo={setSelectedUserInfo}
                    setisUserDetailModalOpen={setisUserDetailModalOpen}
                    setOpenDialog={setOpenDialog}
                    setEmail={setEmail}
                    NoRecordFound={NoRecordFound}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                  />
                  <div className="flex justify-end mt-2 gap-2">
                    {totalPages > 1 && (
                      <PaginationButtons
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {isUserDetailModalOpen && (
            <UserDetailModal
              user={selectedUserInfo}
              isUserDetailModalOpen={isUserDetailModalOpen}
              onClose={closeUserDetailModal}
            />
          )}
          {paymentDetailsModalOpen && (
            <UserPaymentDetails
              user={selectedUserInfo}
              paymentDetailsModalOpen={paymentDetailsModalOpen}
              onClose={closePaymentDetailsModal}
            />
          )}
        </>
      )}
      <Dialogs
        openDialog={openDialog}
        handelClose={() => setOpenDialog(false)}
        handelProcess={handleDeleteUser}
      />
    </div>
  );
};
export default Customer;
