import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { callAPI } from "../../services/apiService";
import Dialogs from "../../components/Common/Dialog";
import { Toast } from "../../components/Common/Toast";
import SearchInput from "../../components/Common/SearchInput";
import Button from "../../components/Common/Button";
import { convertTimestampToDateTime } from "../../helper/helper";
import { PlanForm } from "./Forms/PlanForm";
import { HTTP_STATUS_200, LOCAL_STORAGE_TOKEN, ROLE_ADMIN, ROLE_SUPERADMIN } from "../../helper/Constant";
import { useNavigate } from "react-router-dom";
import TableHeader from "../../components/Common/TableHeader";
import PaginationButtons from "../../components/Common/Pagination";
import { debounce } from "../../helper/Debounce";
import { NoRecordFound } from "../../components/Common/NoRecordFound";
import { getPlans } from "../Builder/builderHelpers";

const Plans = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [planId, setPlanId] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  // const [searchText, setSearchText] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [plans, setPlans] = useState([]);
  const [planDetails, setPlanDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/admin");
    }
  });

  useEffect(() => {
    getPlans(setPlans,null);
  }, []);

  useEffect(() => {
    if (planDetails) setShowForm(true);
  }, [planDetails]);

  // const debouncedSearchPlans = debounce(searchPlans, 300);

  // useEffect(() => {
  //   if (searchText.length >= 3) debouncedSearchPlans();
  //   if (!searchText) getPlans(setPlans);
  // }, [searchText]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  const handleDelete = async () => {
    try {
      setDeleteLoader(true);
      const response = await callAPI(`admin/plan/delete`, {
        planId,
      });
      // console.log(response, "response");
      if (response?.status === HTTP_STATUS_200) {
        Toast("Plan Deleted Successfully");
        getPlans(setPlans);
      } else Toast(response?.message, true);
      setOpenDialog(false);
      setDeleteLoader(false);
    } catch (err) {
      Toast("Plan Delete Failed", true);
      setDeleteLoader(false);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="p-0 md:p-0  bg-storytan">
      {showForm ? (
        <PlanForm
          setShowForm={setShowForm}
          planDetails={planDetails}
          getPlans={getPlans}
          setPlans={setPlans}
          setPlanDetails={setPlanDetails}
        />
      ) : (
        <>
          <div>
            <div className="flex justify-between items-baseline flex-wrap gap-2 my-5">
              <Button
                title="New Plan"
                className="w-40 flex justify-center gap-2 bg-storyblue text-white font-bold rounded-md text-base p-[0.4rem]"
                onclick={() => {
                  setShowForm(true);
                  setPlanDetails(null);
                }}
              />
              {/* <SearchInput placeholder={"Search Plan"} onChange={(e) => setSearchText(e.target.value)} /> */}
            </div>
          </div>
          {loading ? (
            <div className="w-full h-80 flex justify-center items-center">
              <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
            </div>
          ) : (
            <div className="w-full overflow-auto h-[55vh] md:h-[63vh] flex flex-col justify-between">
              <div className="h-[17rem] md:h-[34rem]">
                <table className="min-w-full border border-gray-300">
                  {TableHeader([
                    "Plan Id",
                    "Plan",
                    "Cost",
                    "Charge Cycle",
                    "Image Credits Per Book",
                    "Story Creation Limit",
                    "Book Pages",
                    "",
                  ])}

                  <tbody>
                    {plans && plans?.length > 0 ? (
                      plans?.map((plan, index) => (
                        <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                          <td className="text-left py-2 px-4 border-b ">{plan?.id}</td>
                          <td className="text-left py-2 px-4 border-b capitalize">{plan?.name}</td>
                          <td className="text-left py-2 px-4 border-b">
                            {plan?.cost ? `$ ${parseFloat(plan?.cost).toFixed(2)} ` : "-"}
                          </td>
                          <td className="text-left py-2 px-4 border-b capitalize">
                            {plan?.chargeCycle || "-"}
                          </td>
                          <td className="text-left py-2 px-4 border-b">{plan?.credits || "-"}</td>
                          <td className="text-left py-2 px-4 border-b">
                            {plan?.storyCreationLimit || "Unlimited"}
                          </td>
                          <td className="text-left py-2 px-4 border-b">{plan?.maxBookPage || "-"}</td>
                          <td className="text-left py-4 px-4 border-b flex gap-5">
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              className="cursor-pointer"
                              title="Edit Plan"
                              onClick={() => setPlanDetails(plan)}
                            />
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="cursor-pointer"
                              title="Delete Plan"
                              onClick={() => {
                                setOpenDialog(true);
                                setPlanId(plan.id);
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoRecordFound colSpan={8} />
                    )}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end mt-4 gap-2">
                {totalPages > 1 && (
                  <PaginationButtons
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          )}
          <Dialogs
            openDialog={openDialog}
            handelClose={handleClose}
            handelProcess={handleDelete}
            loader={deleteLoader}
          />
        </>
      )}
    </div>
  );
};
export default Plans;
