import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebookF, faYoutube, faTiktok, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { getCurrentYear } from "../../helper/helper";
const Footer = ({className}) => {
  const logo = "/logo.webp";
  const payment = "./payment.png";
  
  
  return (
    // <div className=" max-w-7xl flex flex-col gap-1 bg-white my-4 pl-[1rem]  ">
    <div
      className={
        className
          ? `${className} flex flex-col gap-1 bg-white my-4 pl-[1rem] `
          : "max-w-7xl flex flex-col gap-1 bg-white my-4 pl-[1rem]"
      }
    >
      {/* <hr className="text-[#5198fb] my-10" /> */}
      {/* <div className="flex flex-col md:flex-row justify-around items-start flex-wrap"> */}
      <div className="flex flex-col items-start gap-[.25rem] ">
        <strong className="text-storyblue text-xs md:text-sm mx-0 ">BuildMyStory © {getCurrentYear()}</strong>
        <div className=" flex flex-col md:flex-row items-start gap-4">
          <Link
            className="bg-transparent text-[#c0bab0] font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm "
            to="https://buildmystory.ai/terms-and-conditions/"
            target="_blank"
          >
            Terms of Service
          </Link>
          <Link
            className="bg-transparent text-[#c0bab0] font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm "
            to="https://buildmystory.ai/privacy-policy/"
            target="_blank"
          >
            Confidentiality Policy
          </Link>
          <Link
            to="https://buildmystory.ai/cookie-policy/"
            target="_blank"
            className="bg-transparent text-[#c0bab0] font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm "
          >
            Cookies
          </Link>
        </div>

        {/* <Link to="/" className="flex-shrink-0 flex items-center">
            <img className="h-10 md:h-16 w-auto" src={logo} alt="Build My Story" />
          </Link>
          <h1 className="text-storyblue text-base md:text-xl font-bold mb-0 md:mb-8">Secure Payments</h1>
          <img className="w-44 mb-6 md:mb-4" src={payment} alt="Build My Story" /> */}
      </div>
      {/* <div className="flex flex-col md:flex-row items-start gap-8 md:gap-32">
          <div className="w-60 flex flex-col items-start gap-4">
            <h1 className="text-storyblue text-base md:text-xl font-bold mb-0 md:mb-8">Company</h1>
            <Link
              className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg"
              to="https://buildmystory.ai/#about"
              target="_blank"
            >
              About Us
            </Link>
            <Link
              className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg"
              to="https://buildmystory.ai/terms-and-conditions/"
              target="_blank"
            >
              Terms And Conditions
            </Link>
            <Link
              className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg"
              to="https://buildmystory.ai/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              to="https://buildmystory.ai/cookie-policy/"
              target="_blank"
              className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg"
            >
              Cookie Policy
            </Link>
            <Link className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg">
              Patents Pending
            </Link>

            <Link
              className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg"
              to="https://buildmystory.ai/blog/"
              target="_blank"
            >
              Our Blogs
            </Link>
            <Link
              className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg"
              to="https://buildmystory.ai/affiliate-program/"
              target="_blank"
            >
              Affiliate Program
            </Link>
          </div>
          <div className="w-40 flex flex-col items-start gap-4">
            <h1 className="text-storyblue text-base md:text-xl font-bold mb-0 md:mb-8">Resources</h1>
            <Link
              className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg"
              to="https://buildmystory.ai/#faq"
              target="_blank"
            >
              FAQ
            </Link>
            <Link
              className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg"
              to="https://buildmystory.ai/#help"
              target="_blank"
            >
              Contact
            </Link>
            <Link
              className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg"
              to="https://buildmystory.ai/#pricing"
              target="_blank"
            >
              Pricing
            </Link>
            <Link className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-sm md:text-lg">
              Login
            </Link>
          </div>
          <div className="flex flex-col items-start gap-4">
            <h1 className="text-storyblue text-base md:text-xl font-bold mb-0 md:mb-8">Socials</h1>
            <div className="flex flex-row md:flex-col gap-12 md:gap-4">
              <Link className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-2xl">
                <FontAwesomeIcon icon={faInstagram} className="cursor-pointer" title="Instagram" />
              </Link>
              <Link className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-2xl">
                <FontAwesomeIcon icon={faYoutube} className="cursor-pointer" title="Youtube" />
              </Link>
              <Link className="bg-transparent text-storyblue font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-2xl">
                <FontAwesomeIcon icon={faFacebookF} className="cursor-pointer" title="Facebook" />
              </Link>
              <Link className="bg-transparent text-storyblue  font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-2xl">
                <FontAwesomeIcon icon={faTiktok} className="cursor-pointer" title="Twitter" />
              </Link>
              <Link className="bg-transparent text-storyblue  font-medium duration-500 hover:duration-5000 hover:text-[#61cbb7] hover:font-bold text-2xl">
                <FontAwesomeIcon icon={faTwitter} className="cursor-pointer" title="Twitter" />
              </Link>
            </div>
          </div>
        </div> */}
      {/* </div> */}
      {/* <p className="text-storyblue text-sm md:text-lg mx-auto md:mx-0 md:text-end">
        © {getCurrentYear()} Build My Story. All Rights Reserved
      </p> */}
    </div>
  );
};
export default Footer;
