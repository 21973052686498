import React, { useEffect, useState } from "react";
import SubmitButton from "../../../components/Common/SubmitButton";
import { callAPI } from "../../../services/apiService";
import { Toast } from "../../../components/Common/Toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  COUPON_DURATION_FOREVER,
  COUPON_DURATION_ONCE,
  COUPON_DURATION_REPEATING,
  COUPON_FOR_BOOK_PURCHASE,
  COUPON_FOR_CREDITS_PURCHASE,
  COUPON_FOR_PLAN_PURCHASE,
  HTTP_STATUS_200,
} from "../../../helper/Constant";
export const CouponForm = ({ setShowForm, getCoupons, couponDetails }) => {
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const validationSchema = Yup.object()
    .shape({
      couponCode: Yup.string().required("Coupon Code is required"),
      couponType: Yup.string().required("Coupon Type is required"),
      couponAmount: Yup.string().required("Coupon Amount / Percentage is required"),
      couponAppliedFor: Yup.string().required("Coupon For is required"),
      couponExpiry: Yup.string().required("Coupon Expiry is required"),
    })
    .when(["couponValidity", "couponValidForMonths"], {
      is: (couponValidity, couponValidForMonths) => couponValidity === COUPON_DURATION_REPEATING,
      then: Yup.object({
        couponValidForMonths: Yup.string().required("Number of months is required"),
      }),
    });

  const formik = useFormik({
    initialValues: {
      couponId: "",
      couponCode: "",
      couponType: "",
      couponAmount: "",
      couponAppliedFor: "",
      couponExpiry: "",
      couponValidity: "",
      couponValidForMonths: "",
      couponDescription: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      setLoader(true);

      let coupon = formik?.values?.couponCode?.toUpperCase();
      const response = await callAPI("admin/coupon/save", {
        couponId: formik.values.couponId,
        couponCode: formik.values.couponCode,
        couponType: formik.values.couponType,
        couponAmount: formik.values.couponAmount,
        couponAppliedFor: formik.values.couponAppliedFor,
        couponExpiry: formik.values.couponExpiry,
        couponValidity: formik.values.couponValidity,
        couponValidForMonths: formik.values.couponValidForMonths,
        couponDescription: formik.values.couponDescription,
      });
      if (response?.status !== HTTP_STATUS_200) Toast(response?.message, true);
      else {
        Toast("Coupon Saved Successfully");
        getCoupons();
        setShowForm(false);
        formik.resetForm();
      }
      setLoader(false);
    } catch {
      Toast("Coupon Failed To Save", true);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (couponDetails) {
      const values = {
        couponId: couponDetails.couponId,
        couponCode: couponDetails?.data?.couponCode,
        couponType: couponDetails?.data?.couponType,
        couponAmount: couponDetails?.data?.couponAmount,
        couponAppliedFor: couponDetails?.data?.couponAppliedFor,
        couponExpiry: couponDetails?.data?.couponExpiry,
        couponValidity: couponDetails?.data?.couponValidity,
        couponValidForMonths: couponDetails?.data?.couponValidForMonths,
      };
      formik.setValues(values);
    }
  }, [couponDetails]);
  return (
    <div className="bg-storyta mx-auto">
      <div className="max-w-7xl mx-auto min-h-screen">
        {couponDetails ? (
          <h1 className="text-storyblue text-xl font-bold text-start mb-4">Edit Coupon</h1>
        ) : (
          <h1 className="text-storyblue text-xl font-bold text-start mb-4">New Coupon</h1>
        )}
        <form id="form-signup" name="form-signup" className="text-start " onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col gap-2">
              <label className="font-medium">Coupon Code</label>
              <input
                type="text"
                name="couponCode"
                value={formik.values.couponCode}
                onChange={formik.handleChange}
                placeholder="Coupon Code"
                className="p-2 w-full rounded-md uppercase"
                autoFocus={true}
              />
              <div className="text-red-600 mt-1">{formik.errors.couponCode}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Select Coupon Type</label>
              <select
                name="couponType"
                value={formik.values.couponType}
                onChange={formik.handleChange}
                className="p-2 w-full rounded-md"
              >
                <option value="" disabled>
                  Select Coupon Type
                </option>
                <option value="fixed">Fixed</option>
                <option value="percentage">Percentage</option>
              </select>
              <div className="text-red-600 mt-1">{formik.errors.couponType}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Coupon Amount / Percentage</label>
              <input
                type="text"
                name="couponAmount"
                value={formik.values.couponAmount}
                onChange={formik.handleChange}
                placeholder="Coupon Amount / Percentage"
                className="p-2 w-full rounded-md"
              />
              <div className="text-red-600 mt-1">{formik.errors.couponAmount}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Select Coupon Applied For</label>
              <select
                name="couponAppliedFor"
                value={formik.values.couponAppliedFor}
                onChange={formik.handleChange}
                className="p-2 w-full rounded-md"
              >
                <option value="" disabled>
                  Select Coupon Applied For
                </option>
                <option value={COUPON_FOR_BOOK_PURCHASE}>Book Purchase</option>
                <option value={COUPON_FOR_PLAN_PURCHASE}>Plan Purchase</option>
                <option value={COUPON_FOR_CREDITS_PURCHASE}>Credits Purchase</option>
              </select>
              <div className="text-red-600 mt-1">{formik.errors.couponAppliedFor}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Coupon Expiry Date</label>
              <input
                type="date"
                min={new Date().toISOString().split("T")[0]}
                name="couponExpiry"
                value={formik.values.couponExpiry}
                onChange={formik.handleChange}
                placeholder="Coupon Expiry"
                className="p-2 w-full rounded-md"
                readOnly={couponDetails ? true : false}
              />
              <div className="text-red-600 mt-1">{formik.errors.couponExpiry}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Select Duration</label>
              <select
                name="couponValidity"
                value={formik.values.couponValidity}
                onChange={formik.handleChange}
                className="p-2 w-full rounded-md"
              >
                <option value="" disabled>
                  Select Duration
                </option>
                <option value={COUPON_DURATION_ONCE}>Once</option>
                <option value={COUPON_DURATION_FOREVER}>Unlimited</option>
                {/* <option value={COUPON_DURATION_REPEATING}>Multiple Months</option> */}
              </select>
              <div className="text-red-600 mt-1">{formik.errors.couponValidity}</div>
            </div>
            <div className="flex flex-col gap-2 mt-1">
              <label className="font-medium">Coupon Description</label>
              <textarea
                name="couponDescription"
                value={formik.values.couponDescription}
                onChange={formik.handleChange}
                className="p-2 w-full rounded-md"
                rows="2"
                maxLength={100}
                placeholder="Enter coupon description"
              />
              <div className="text-red-600 mt-1">{formik.errors.couponDescription}</div>
            </div>
            {formik.values.couponValidity === COUPON_DURATION_REPEATING && (
              <div>
                <label className="font-medium">Number of Months</label>
                <select
                  name="couponValidForMonths"
                  value={formik.values.couponValidForMonths}
                  onChange={formik.handleChange}
                  className="p-2 w-full rounded-md"
                >
                  <option value="">Select Number of Months</option>
                  {[...Array(12)].map((_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1} Month{index !== 0 ? "s" : ""}
                    </option>
                  ))}
                </select>
                <div className="text-red-600 mt-1">{formik.errors.couponValidForMonths}</div>
              </div>
            )}
          </div>
          <SubmitButton title="Save" loader={loader} cancelOperation={setShowForm} />
        </form>
      </div>
    </div>
  );
};
