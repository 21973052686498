import { useEffect, useState } from "react";
import { useApp } from "../../context/appContext";
import { updateBookForFreeMode } from "../../pages/Builder/builderHelpers";
import { useUser } from "../../context/userContext";
import { useStory } from "../../context/storyContext";
import { handleContinueBookInFreeMode } from "../../helper/helper";

const Modal = ({
  title,
  description,
  doProcess,
  submitButtonTitle = "Ok",
  showCloseButton = true,
  showPromoFreeBook = false,
  setImageGenerationStarted = null,
  characterRegenerate = false,
  continueBook = null,
  showCloseButtonFunction = null,
  showFreeBookViaRelaxMode=null
}) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [showSwitchButton, setShowSwitchButton] = useState(false);
  const { user } = useUser();
  const { bookId } = useApp();
  const{setStoryData, storyData} = useStory();





  
  useEffect(() => {
    setShowSwitchButton(user?.plan?.maintenanceMode);
  }, []);

  return (
    <div
      className={`relative z-10 ${modalOpen === true ? "block" : "hidden"}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-full overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-8 md:p-4 text-center">
          <div className="relative transform overflow-hidden rounded-lg bg-storytan px-8 pb-4 pt-5 text-left shadow-xl transition-all  sm:max-w-6xl">
            {/* Content of the modal */}
            <h2 className="text-left text-2xl md:text-2xl mb-4 font-bold text-storyblue">{title}</h2>
            <div className="grid grid-cols-1 w-[500px] md: gap-6">
              <div style={{ display: "block" }}>
                <h3 className="text-sm text-gray-500 font-bold ">{description}</h3>
              </div>
            </div>

            <div className="w-full flex justify-end gap-3 mt-1">
              {/* {showPromoFreeBook &&(
                  <button
                    className="h-11 capitalize flex items-center justify-center rounded-md border border-transparent bg-storygreen px-4 py-2 text-base   text-white shadow-sm"
                    onClick={() => {
                      updateBookForFreeMode(bookId);
                      setImageGenerationStarted(false);
                      getShapshotData();
                      setModalOpen(false);
                    }}
                  >
                    Continue in Free Mode
                  </button>
                )} */}
              {showPromoFreeBook && !showSwitchButton && !characterRegenerate && (
                <button
                  className="h-11 capitalize flex items-center justify-center rounded-md border border-transparent bg-storygreen px-4 py-2 text-base text-white shadow-sm"
                  onClick={() => {
                    updateBookForFreeMode(bookId, setStoryData, storyData);
                    setImageGenerationStarted(false);

                    setModalOpen(false);
                  }}
                >
                  Continue in Free Mode
                </button>
              )}
              {/* {showPromoFreeBook && characterRegenerate && continueBook && (
                <button
                  className="h-11 capitalize flex items-center justify-center rounded-md border border-transparent bg-storygreen px-4 py-2 text-base text-white shadow-sm"
                  onClick={() => {
                    continueBook();
                  }}
                >
                  Continue Book in Free Mode
                </button>
              )} */}
              <button
                className="h-11 capitalize flex items-center justify-center rounded-md border border-transparent bg-storyblue px-4 py-2 text-base   text-white shadow-sm"
                onClick={() => doProcess()}
                style={{ display: "block" }}
              >
                {submitButtonTitle}
              </button>
              {showCloseButton && (
                <button
                  className="h-11 capitalize flex items-center justify-center rounded-md border border-transparent bg-storygreen px-4 py-2 text-base   text-white shadow-sm"
                  onClick={() => {
                    setModalOpen(false);
                    if (showCloseButtonFunction) {
                      showCloseButtonFunction();
                    }
                  }}
                >
                  Close
                </button>
              )}

              {showFreeBookViaRelaxMode && (
                <button
                  className="h-11 capitalize flex items-center justify-center rounded-md border border-transparent bg-storygreen px-4 py-2 text-base   text-white shadow-sm"
                  onClick={() => {
                    setModalOpen(false);
                    handleContinueBookInFreeMode(bookId);
                  }}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
