import React, { useState, useEffect } from "react";
// Import FontAwesome icons as needed
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faBan,
  faSackDollar,
  faBookOpen,
  faEdit,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

import { convertTimestampToDateTime } from "../../helper/helper";
import { ROLE_SUPERADMIN, USER_STATUS_ACTIVE, USER_STATUS_INACTIVE } from "../../helper/Constant";

const UsersTable = ({
  allUsers,
  onOptionClick,
  onSelectUser,
  handleStatusChange,
  loggedinUser,
  handleEditCredits,
  setParentCall,
  PARENT_CALL_CUSTOMER,
  getUserBooks,
  setSelectedUserInfo,
  setisUserDetailModalOpen,
  setOpenDialog,
  setEmail,
  NoRecordFound,
  sortOrder,
  setSortOrder,
  sortBy,
  setSortBy,

}) => {
  // const [sortedUsers, setSortedUsers] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);

   const handleSort = (criteria) => {
     setSortBy(criteria);
     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
   };

  // useEffect(() => {
  //   let sorted = [...allUsers];

  //   // Sort based on criteria
  //   if (sortBy === "name") {
  //     sorted.sort((a, b) => {
  //       const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
  //       const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
  //       return sortOrder === "asc" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
  //     });
  //   } else if (sortBy === "signup") {
  //     sorted.sort((a, b) => {
  //       return sortOrder === "asc" ? a.timestamp - b.timestamp : b.timestamp - a.timestamp;
  //     });
  //   }

  //   setSortedUsers(sorted);
  // }, [allUsers, ,sortOrder sortBy]);


  return (
    <div className="w-full overflow-auto h-[22rem] md:h-[34rem] flex flex-col justify-between">
      <table className="min-w-full border border-gray-300">
        <thead className="bg-gray-200 text-gray-700">
          <tr>
            <th className="cursor-pointer py-2 text-left px-4 border-b" onClick={() => handleSort("name")}>
              Customer Name
              {sortBy === "name" && (
                <span className="inline-block ml-2">
                  <img
                    src={sortOrder === "asc" ? "/sort-up.svg" : "/sort-down.svg"}
                    alt="Sort Icon"
                    className={`w-6 h-6 inline ${sortBy === "name" ? "text-black" : "text-gray-400"}`}
                  />
                </span>
              )}
            </th>
            <th className="py-2 text-left px-4 border-b">Customer Email</th>
            <th className="py-2 text-left px-4 border-b">Book Balance</th>
            <th className="cursor-pointer py-2 text-left px-4 border-b" onClick={() => handleSort("signup")}>
              Subscribed At
              {sortBy === "signup" && (
                <span className="inline-block ml-2">
                  <img
                    src={sortOrder === "asc" ? "/sort-up.svg" : "/sort-down.svg"}
                    alt="Sort Icon"
                    className={`w-6 h-6 inline ${sortBy === "signup" ? "text-black" : "text-gray-400"}`}
                  />
                </span>
              )}
            </th>
            <th className="capitalize py-2 text-left px-4 border-b">Plan</th>
            <th className="py-2 text-left px-4 border-b">Status</th>
            <th className="py-2 text-left px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {allUsers.length > 0 ? (
            allUsers.map((user, index) =>{
             
             return (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                <td
                  className="py-2 text-left px-4 border-b cursor-pointer underline text-storygreen"
                  onClick={() => {
                    onOptionClick("Orders");
                    onSelectUser(user?.email);
                  }}
                >
                  <FontAwesomeIcon
                    icon={user?.status === USER_STATUS_ACTIVE && user.planRef ? faCircleCheck : faBan}
                    className="mr-2"
                    title="Status"
                    style={{
                      color: user?.status === USER_STATUS_ACTIVE && user.planRef ? "green" : "red",
                    }}
                  />
                  {user?.firstName} {user?.lastName}
                </td>
                <td className="py-2 text-left px-4 border-b">{user?.email}</td>
                <td className="py-2 text-left px-4 border-b">
                  {parseInt(user?.booksCreateLimit || 0) - parseInt(user?.booksCreatedCounter || 0)}
                </td>
                <td className="py-2 text-left px-4 border-b">
                  {convertTimestampToDateTime(user?.timestamp)}
                </td>
                <td className="capitalize">{user?.plan?.name || "-"}</td>
                <td className="py-2 text-left px-4 border-b">
                  <select
                    value={user?.status ? user?.status : USER_STATUS_INACTIVE}
                    disabled={loggedinUser?.role !== ROLE_SUPERADMIN}
                    onChange={(e) => handleStatusChange(user?.id, e.target.value)}
                    className="outline-none"
                  >
                    <option value={USER_STATUS_ACTIVE}>Active</option>
                    <option value={USER_STATUS_INACTIVE}>Inactive</option>
                  </select>
                </td>
                <td className="flex gap-4 py-4 px-4 border-b">
                  <FontAwesomeIcon
                    icon={faSackDollar}
                    className="cursor-pointer"
                    title="Topup Book Credits"
                    onClick={() => {
                      setSelectedBook(null);
                      handleEditCredits(user?.id);
                      setParentCall(PARENT_CALL_CUSTOMER);
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faBookOpen}
                    className="cursor-pointer"
                    title="Books"
                    onClick={() => {
                      getUserBooks(user?.email);
                      setSelectedUserInfo(user);
                    }}
                  />
                  {loggedinUser?.role === ROLE_SUPERADMIN && (
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="cursor-pointer"
                      title="Edit Customer"
                      onClick={() => {
                        setSelectedUserInfo(user);
                        setisUserDetailModalOpen(true);
                      }}
                    />
                  )}
                  {loggedinUser?.role === ROLE_SUPERADMIN && (
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className="cursor-pointer"
                      title="Delete"
                      onClick={() => {
                        setOpenDialog(true);
                        setEmail(user?.email);
                      }}
                    />
                  )}
                </td>
              </tr>
            )})
          ) : (
            <NoRecordFound colSpan={7} />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;

//    <div className="w-full overflow-auto h-[22rem] md:h-[34rem] flex flex-col justify-between ">
//                     <table className="min-w-full border border-gray-300">
//                       {TableHeader([
//                         "Customer Name",
//                         "Customer Email",
//                         "Book Balance",
//                         "Subscribed At",
//                         "Plan",
//                         "Status",
//                         "",
//                       ])}

//                       <tbody>
//                         {allUsers && allUsers.length > 0 ? (
//                           allUsers?.map((user, index) => (
//                             <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
//                               <td
//                                 className="py-2 text-left px-4 border-b cursor-pointer underline text-storygreen"
//                                 onClick={() => {
//                                   onOptionClick("Orders");
//                                   onSelectUser(user?.email);
//                                 }}
//                               >
//                                 <FontAwesomeIcon
//                                   icon={
//                                     user?.status === USER_STATUS_ACTIVE && user.planRef
//                                       ? faCircleCheck
//                                       : faBan
//                                   }
//                                   className=" mr-2 cursor-pointer"
//                                   title="Status"
//                                   style={{
//                                     color:
//                                       user?.status === USER_STATUS_ACTIVE && user.planRef ? "green" : "red",
//                                   }}

//                                   // onClick={() => {
//                                   //   setOpenDialog(true);
//                                   //   setEmail(user?.email);
//                                   // }}
//                                 />
//                                 {user?.firstName} {user?.lastName}
//                               </td>
//                               <td className="py-2 text-left px-4 border-b">{user?.email}</td>
//                               {/* <td className="py-2 text-left px-4 border-b">name</td> */}
//                               <td className="py-2 text-left px-4 border-b">
//                                 {parseInt(user?.booksCreateLimit || 0) -
//                                   parseInt(user?.booksCreatedCounter || 0)}
//                               </td>
//                               <td className="py-2 text-left px-4 border-b">
//                                 {convertTimestampToDateTime(user?.timestamp)}
//                               </td>
//                               <td className="capitalize">
//                                 {/* {user?.planRef ? user?.planRef?._path?.segments[1] : "-"} */}
//                                 {user?.plan ? user?.plan?.name : "-"}
//                               </td>
//                               <td className="py-2 text-left px-4 border-b">
//                                 <select
//                                   value={user?.status ? user?.status : USER_STATUS_INACTIVE}
//                                   disabled={loggedinUser?.role != ROLE_SUPERADMIN}
//                                   onChange={(e) => handleStatusChange(user?.id, e.target.value)}
//                                   className="outline-none"
//                                 >
//                                   <option value={USER_STATUS_ACTIVE}>Active</option>
//                                   <option value={USER_STATUS_INACTIVE}>Inactive</option>
//                                 </select>
//                               </td>
//                               <td className="flex gap-4 py-4 px-4 border-b">
//                                 {/* <FontAwesomeIcon
//                                icon={faBoxOpen}
//                          className="cursor-pointer"
//                             onClick={() => {
//                               onOptionClick("Physical Book Orders");
//                              onSelectUser(user?.email);
//                               }}
//                              /> */}
//                                 <FontAwesomeIcon
//                                   icon={faSackDollar}
//                                   className="cursor-pointer"
//                                   title="Topup Book Credits"
//                                   onClick={() => {
//                                     setSelectedBook(null);
//                                     // setSelectedUserInfo(user);
//                                     handleEditCredits(user?.id);
//                                     setParentCall(PARENT_CALL_CUSTOMER);
//                                   }}
//                                 />
//                                 <FontAwesomeIcon
//                                   icon={faBookOpen}
//                                   className="cursor-pointer"
//                                   title="Books"
//                                   onClick={() => {
//                                     getUserBooks(user?.email);
//                                     setSelectedUserInfo(user);
//                                   }}
//                                 />

//                                 {loggedinUser?.role === ROLE_SUPERADMIN && (
//                                   <FontAwesomeIcon
//                                     icon={faEdit}
//                                     className="cursor-pointer"
//                                     title="Edit Customer"
//                                     onClick={() => {
//                                       // editUser(user?.email);
//                                       setSelectedUserInfo(user);
//                                       setisUserDetailModalOpen(true);
//                                     }}
//                                   />
//                                 )}
//                                 {/* <FontAwesomeIcon
//                             icon={faMoneyCheckDollar}
//                             className="cursor-pointer"
//                             title="Payment History"
//                             onClick={() => {
//                               // editUser(user?.email);
//                               setSelectedUserInfo(user);
//                               setPaymentDetailsModalOpen(true);
//                             }}
//                           /> */}

//                                 {loggedinUser?.role === ROLE_SUPERADMIN && (
//                                   <FontAwesomeIcon
//                                     icon={faTrashCan}
//                                     className="cursor-pointer"
//                                     title="Delete"
//                                     onClick={() => {
//                                       setOpenDialog(true);
//                                       setEmail(user?.email);
//                                     }}
//                                   />
//                                 )}
//                               </td>
//                             </tr>
//                           ))
//                         ) : (
//                           <NoRecordFound colSpan={7} />
//                         )}
//                       </tbody>
//                     </table>
//                   </div>
