import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { useUser } from "../../context/userContext";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import Loader from "../../components/Common/Loader";
import Heading from "../../components/Common/Heading";
import Footer from "../../components/Footer/Footer";
import { HTTP_STATUS_200, PROMO_PLAN } from "../../helper/Constant";
import Dialogs from "../../components/Common/Dialog";
import PlanPurchase from "../../components/Common/PlanPurchase";
import ThankYou from "../../components/Common/ThankYou";
import { goToTopOfPage } from "../../helper/helper";
import Guide from "../../components/Common/Guide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const MyPlans = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [showThanksNote, setShowThanksNote] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPlanDescription, setCurrentPlanDescription] = useState(null);
  const [loader, setLoader] = useState({
    profileUpdate: false,
    removeSubscription: false,
  });
  useEffect(() => {
    goToTopOfPage();
  }, []);
  useEffect(() => {
    setCurrentPlanDescription(user?.plan);
  }, [user]);
  const cancelSubscription = async () => {
    setOpenDialog(false);
    setLoader({ ...loader, removeSubscription: true });
    const cancelSub = await callAPI("user/cancel-subscription", { email: user?.email });
    Toast(
      cancelSub?.message || "Unexpected Error Occurred",
      cancelSub?.status === HTTP_STATUS_200 ? false : true
    );
    setLoader({ ...loader, removeSubscription: false });
  };
  const goBack = () => {
    // navigate(-1);
    setShowThanksNote(false);
  };

  console.log(
    "currentPlanDescription",
    user,
    user?.plan?.freePhysicalBookStatus,
    parseInt(user?.freePhysicalBookCreated),
    parseInt(user?.freePhysicalBookQuantity),
    "condition======>>",
    user?.plan?.freePhysicalBookStatus === true &&
      parseInt(
        parseInt(user?.freePhysicalBookCreated)
          ? parseInt(user?.freePhysicalBookCreated)
          : 0) <= parseInt(user?.freePhysicalBookQuantity)
      
  );

  return (
    <div className="App">
      <NavigationBar />
      <div className="bg-storytan p-4 mx-auto">
        {/* {purchasePlan ? ( */}
        {/* // ) : ( */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {showThanksNote ? (
            <ThankYou goBack={goBack} />
          ) : (
            <>
              <div className="flex justify-between items-baseline">
                {/* <Heading title="My Plan" /> */}
                <div className=" flex items-center align-end mb-2">
                  <Heading title="My Plan" />
                  <div className="mt-1">
                    <Guide />
                  </div>
                </div>
                <button
                  className="px-4 bg-storygreen text-white font-bold rounded-md text-sm h-11"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </button>
              </div>

              <div
                className="flex-col mt-8 w-full flex md:flex-row  gap-4 justify-between text-start"
                // onSubmit={handleSubmit}
              >
                {
              (  user?.plan?.freePhysicalBookStatus === true &&
      parseInt(
        parseInt(user?.freePhysicalBookCreated)
          ? parseInt(user?.freePhysicalBookCreated)
          : 0) <= parseInt(user?.freePhysicalBookQuantity)
                ) ? (
                  ""
                ) : (
                  <div
                    className={
                      user?.plan == null || user?.plan?.name === PROMO_PLAN
                        ? "hidden"
                        : " sm:w-[100%] md:w-[50%] "
                    }
                    // className={`  ${user?.plan} == null || ${user?.plan?.name} === ${PROMO_PLAN} ? "hidden" : "w-[50%]"`}
                  >
                    {user?.plan?.name && user?.plan?.name !== PROMO_PLAN && (
                      <div className="flex flex-col gap-2 text-base font-medium text-storyblue">
                        <div className="flex justify-start items-start gap-4">
                          <FontAwesomeIcon icon={faCheck} className=" text-2xl text-storylightgreen" />
                          <h1>
                            Access to "Book Builder" craft personalized children's books for your loved one.
                          </h1>
                        </div>
                        <div className="flex justify-start items-start gap-4">
                          <FontAwesomeIcon icon={faCheck} className=" text-2xl text-storylightgreen" />
                          <h1>Unlimited Story Editing.</h1>
                        </div>
                        <div className="flex justify-start items-start gap-4">
                          <FontAwesomeIcon icon={faCheck} className=" text-2xl text-storylightgreen" />
                          <h1>Unlimited PDFs Generated.</h1>
                        </div>
                      </div>
                    )}
                    {user?.plan?.name && user?.plan?.name !== PROMO_PLAN && (
                      <div className=" flex flex-col gap-2 ">
                        <div className="flex gap-4 justify-between mt-4 ">
                          <div className=" w-full flex flex-col gap-1">
                            {/* <h1 className="font-bold text-storyblue ">Current Plan</h1> */}
                            <Heading title={"Current Plan"} />
                            <input
                              type="text"
                              name="currrentPlan"
                              value={user?.plan?.name}
                              readOnly
                              className="mt-4 p-2 w-full rounded-md text-center"
                            />
                          </div>
                          <button
                            type="button"
                            className="w-full md:w-full flex justify-center  self-end gap-2 bg-storyblue text-white font-bold rounded-md text-sm lg:text-base h-[2.5rem] items-center"
                            disabled={loader?.removeSubscription}
                            onClick={() => setOpenDialog(true)}
                          >
                            Cancel Subscription
                            <Loader loader={loader?.removeSubscription} />
                          </button>
                        </div>
                        <div>
                          {currentPlanDescription && (
                            <div className="bg-white rounded-md border-2 border-storylightgreen flex flex-col gap-1 justify-around items-start w-full my-2 p-2">
                              <h1 className="text-md text-storyblue">
                                <strong> Story Limit: </strong> {currentPlanDescription?.storyCreationLimit}
                              </h1>
                              {/* <h1 className="text-md text-storyblue">
                              <strong>Total Image Credits:</strong>{" "}
                              {parseInt(currentPlanDescription?.credits) *
                                parseInt(currentPlanDescription?.storyCreationLimit)}
                            </h1>
                            <h1 className="text-md text-storyblue">
                              <strong> Additional Image credits: </strong>
                              {currentPlanDescription?.additionalAICredit?.credits} Credits for ${" "}
                              {currentPlanDescription?.additionalAICredit?.price}
                            </h1> */}
                              {currentPlanDescription?.additionalAICredit?.storyCreationLimit > 0 && (
                                <h1 className="text-md text-storyblue">
                                  <strong> Additional Story Limit: </strong>
                                  {currentPlanDescription?.additionalAICredit?.storyCreationLimit} Stories for
                                  $ {currentPlanDescription?.additionalAICredit?.storyCreationCost}
                                </h1>
                              )}

                              {currentPlanDescription?.freePhysicalBookStatus === true &&
                                currentPlanDescription?.freePhysicalBookQuantity && (
                                  <h1 className="text-md text-storyblue">
                                    <strong> Free Physical Book(s): </strong>
                                    {currentPlanDescription?.freePhysicalBookQuantity}
                                  </h1>
                                )}
                              {user?.plan?.name != PROMO_PLAN && (
                                <h1 className="text-md text-storyblue capitalize">
                                  <strong> Cost: </strong> $ {currentPlanDescription?.cost}
                                  {" / "}
                                  {currentPlanDescription?.chargeCycle?.replaceAll("_", " ")}
                                </h1>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className=" sm:w-[100%] md:w-[50%] m-0">
                  <PlanPurchase setShowThanksNote={setShowThanksNote} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      <Dialogs
        openDialog={openDialog}
        handelClose={() => setOpenDialog(false)}
        handelProcess={cancelSubscription}
        confirmMessage="Are you sure you want to cancel Subscription? If subscription cancelled, you may no longer have access to the books you created."
      />
    </div>
  );
};
export default MyPlans;
