import { signInWithPopup } from "firebase/auth";
import { Toast } from "../../components/Common/Toast";
import {
  ERROR_MODULE_CHARACTER_GENERATION,
  HTTP_STATUS_200,
  HTTP_STATUS_400,
  STORY_TYPE_ADVENTURE,
  STORY_TYPE_BEDTIME_STORY,
  STORY_TYPE_EDUCATIONAL,
  STORY_TYPE_FABLE,
  STORY_TYPE_FAIRYTALE,
  STORY_TYPE_MYSTERY,
  STORY_TYPE_REALISTIC_FICTION,
  STORY_TYPE_SCIENCE_FICTION,
  WRITING_STYLE_ACTION_PACKED,
  WRITING_STYLE_EDUCATIONAL,
  WRITING_STYLE_EMPOWERING,
  WRITING_STYLE_FUNNY,
  WRITING_STYLE_HEART_WARMING,
  WRITING_STYLE_IMAGINATIVE,
  WRITING_STYLE_NOSTALGIC,
  WRITING_STYLE_SPOOKY,
  TYPE_CHILD_AGE,
  TYPE_STORY,
  TYPE_WRITING_STYLE,
  TYPE_ART_STYLE,
  LOCAL_STORAGE_TOKEN,
  CHARACTER_TYPE_HUMAN,
  CHARACTER_TYPE_ANIMAL,
  CHARACTER_TYPE_FICTIONAL,
  HTTP_STATUS_403,
  LOCAL_STORAGE_STORY_STEP,
  STORY_STEP_ILLUSTRATION_CREATE,
  STORY_STEP_CHARACTER_BUILD,
  LOCAL_STORAGE_BOOK_ID,
  LOCAL_STORAGE_EDIT_MODE,
  PROMO_PLAN,
  DEDUCT_CREDITS_PER_IMAGE,
  CHARACTER_AGE_UNIT
} from "../../helper/Constant";
import { callAPI } from "../../services/apiService";
import { auth, provider } from "../../firebase";
import * as Yup from "yup";
import { encryptToken } from "../../helper/helper";
import { debounce } from "../../helper/Debounce";

export const SignUpSchema = (step = 1) => {
  let schema = Yup.object().shape({
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    email: Yup.string().email("Invalid Email Address").required("Email Required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*\d).{8,}$/,
        "Password Must Be At Least 8 Characters Long, Include At Least One Uppercase Letter, And One Numeric Digit"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords Must Match")
      .required("Confirm Password Required"),
    isAdult: Yup.boolean().oneOf([true], "Must Be an Adult"),
    // terms: Yup.boolean().oneOf([true], "Must Accept Terms"),
  });

  if (step === 2) {
    schema = schema.shape({
      terms: Yup.boolean().oneOf([true], "Must Accept Terms"), //Yup.boolean().required("Must Accept Terms"),
    });
  }

  return schema;
};

export const SignUpViaGoogleSchema = () => {
  const schema = Yup.object().shape({
    terms: Yup.boolean().oneOf([true], "Must Accept Terms"),
  });
  return schema;
};

export const LogInSchema = () => {
  const schema = Yup.object().shape({
    email: Yup.string().email("Invalid Email Address").required("Email Required"),
    password: Yup.string().required("Password Required").min(8, "Must Be At Least 8 Characters"),
  });
  return schema;
};

export const StoryTypes = () => {
  return [
    {
      value: STORY_TYPE_BEDTIME_STORY,
      label: "Bedtime",
    },
    {
      value: STORY_TYPE_FABLE,
      label: "Fable",
    },
    {
      value: STORY_TYPE_FAIRYTALE,
      label: "Fairytale",
    },
    {
      value: STORY_TYPE_ADVENTURE,
      label: "Adventure",
    },
    {
      value: STORY_TYPE_EDUCATIONAL,
      label: "Educational",
    },
    {
      value: STORY_TYPE_MYSTERY,
      label: "Mystery",
    },
    {
      value: STORY_TYPE_SCIENCE_FICTION,
      label: "Science Fiction",
    },
    {
      value: STORY_TYPE_REALISTIC_FICTION,
      label: "Realistic Fiction",
    },
  ];
};

export const StoryWritingStyle = () => {
  return [
    {
      value: WRITING_STYLE_IMAGINATIVE,
      label: "Imaginative",
    },
    {
      value: WRITING_STYLE_FUNNY,
      label: "Funny",
    },
    {
      value: WRITING_STYLE_HEART_WARMING,
      label: "Heart Warming",
    },
    {
      value: WRITING_STYLE_ACTION_PACKED,
      label: "Action Packed",
    },
    {
      value: WRITING_STYLE_NOSTALGIC,
      label: "Nostalgic",
    },
    {
      value: WRITING_STYLE_EMPOWERING,
      label: "Empowering",
    },
    {
      value: WRITING_STYLE_SPOOKY,
      label: "Spooky",
    },
    {
      value: WRITING_STYLE_EDUCATIONAL,
      label: "Educational",
    },
  ];
};

export const DropdownArrow = () => {
  return (
    <svg
      className="pointer-events-none z-10 right-1 relative col-start-1 row-start-1 h-4 w-4 self-center justify-self-end forced-colors:hidden"
      viewBox="0 0 16 16"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const CharacterType = () => {
  return [
    {
      value: CHARACTER_TYPE_HUMAN,
      label: "Human",
    },
    {
      value: CHARACTER_TYPE_ANIMAL,
      label: "Animal",
    },
    {
      value: CHARACTER_TYPE_FICTIONAL,
      label: "Fictional",
    },
  ];
};

export const IconChecked = () => {
  return (
    <svg
      className="h-6 w-5 flex-none text-indigo-600"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const sortBookPages = (bookData, setPages) => {
  if (bookData && bookData?.pages) {
    const sortedPages = Object.entries(bookData.pages).sort(
      (a, b) => parseInt(a[0].replace("page", "")) - parseInt(b[0].replace("page", ""))
    );
    // console.log("sortBookPages====",sortPages);
    setPages(sortedPages);
  }
};

export const checkSubscribedToPlan = async (user, setLoaders, setPlanSubscribed, loaders) => {
  try {
    setLoaders({ ...loaders, planExist: true });
    const response = await callAPI("api/checkUserSubscription", { email: user?.email });

    if (response?.subscribedToPlan) setPlanSubscribed(true);
    else setPlanSubscribed(false);
  } catch (error) {
    Toast("Failed to get plans", true);
    console.error("Error getting plans:", error);
  } finally {
    setLoaders({ ...loaders, planExist: false });
  }
};

export const getstoryConfig = async (setStoryConfig) => {
  try {
    const response = await callAPI(`api/story-config`);
    if (response && response?.data[0]) {
      setStoryConfig(response?.data[0]);
    } else Toast("Failed to get story config", true);
  } catch (error) {
    Toast("Failed to get story config", true);
    console.error("Failed to get story config:", error);
  }
};

export const getPlans = async (setPlans, userCurrentPlan = null, userReq=false) => {
  try {
    const response = await callAPI(`plans/getPlans`);
// console.log("getPlans====",response);
    if (response && response.plans ) {
      //hide user currently subscribed plan
      let filteredPlans = userCurrentPlan
        ? response?.plans?.filter((plan) => plan?.name !== userCurrentPlan)
        : response.plans;

      //hide promo plan from user
   if(userReq=== true)  filteredPlans = response?.plans?.filter((plan) => plan?.name !== PROMO_PLAN);

      setPlans(filteredPlans);
    } else Toast("Failed to get plans", true);
  } catch (error) {
    Toast("Failed to get plans", true);
    console.error("Error getting plans:", error);
  }
};

export const getArtStyles = async (setArtStyles) => {
  try {
    const response = await callAPI(`admin/artStyle`);
    if (response?.artStyle) setArtStyles(response?.artStyle);
    else Toast("Failed to get art styles", true);
  } catch (error) {
    Toast("Failed to get art styles", true);
    console.error("Error getting art styles:", error);
  }
};

export const fetchBookData = async (bookId) => {
  try {
    const response = await callAPI(`api/books/${bookId}`);
    return response;
  } catch (error) {
    console.error("Error fetching book data:", error);
    return null;
  }
};

export const doStartImageGeneration = async (bookId, showErrorMsg = true) => {
  if (!bookId) {
    console.error("Book ID is undefined or not set");
    return;
  }

  try {
    const response = await callAPI(`api/generateImages/${bookId}`);
    // console.log("doStartImageGen/eration response:", response);
    if (response?.response?.status === HTTP_STATUS_400) {
      // console.log("doStartImageGen/eration response:", response, showErrorMsg);
      if (showErrorMsg === true ) Toast("Need Additional Image Credits", true);
      // Toast("Need Additional Image Credits", true);
      return -1;
    }
    return 1;
  } catch (error) {
    Toast("Unexpected Error Occurred", true);
    console.error("Error starting image generation:", error);
    return 0;
  }
};

export const handleGoogleLogin = (
  setGoogleLoader,
  setUser,
  getUserDetails,
  setSignUpStep,
  setShowLogin,
  setSignupViaGoogle,
  promotionalEmail,
  updateEmail
) => {
  setGoogleLoader(true);
  //setSignUpStep(2);

  signInWithPopup(auth, provider)
    .then(async (result) => {
      // setUser(result.user); // Set the user object on successful login
      // let name = result.user.displayName.split(" ");
      // console.log("getUserDetails", getUserDetails);
      let status = await getUserDetails(result?.user?.email);
      // console.log("new user", status, result.user);

      if (status) {
        setUser(result.user); // Set the user object on successful login
        const userTokenId = result.user ? await result.user.getIdToken() : null;
        if (userTokenId) {
          const encryptedToken = encryptToken(userTokenId, process.env.REACT_APP_TOKEN_ENCRYPT_KEY);
          localStorage.setItem(LOCAL_STORAGE_TOKEN, encryptedToken);
        }
      } else {
        //Toast("User Not Registered!", true);

        let name = result.user.displayName.split(" ");

        const userData = {
          email: result?.user?.email,
          firstName: name[0],
          lastName: name[1],
          authType: "Google",
          plan: null,
          promoCode: null,
          isAdult: true,
          firebaseId: result?.user?.uid,
          promotionalEmail,
          productUpdateEmail: updateEmail,
        };

        setShowLogin(false);
        setSignUpStep(2);
        setSignupViaGoogle(userData);
      }
      // const userData = {
      //   email: result?.user?.email,
      //   firstName: name[0],
      //   lastName: name[1],
      //   authType: "Google",
      //   plan: null,
      //   promoCode: null,
      //   isAdult: true,
      //   firebaseId: result?.user?.uid,
      //   promotionalEmail,
      //   productUpdateEmail: updateEmail,
      // };

      // const userDocRef = doc(collection(db, "users"), result.user.email);
      // const userDocSnapshot = await getDoc(userDocRef);
      // console.log("userDocSnapshot.exists()", !userDocSnapshot.exists());
      // if (!userDocSnapshot.exists()) {
      //   try {
      //     console.log("userData", userData);
      //     await callAPI("api/createUser", userData);

      //     setGoogleLoader(false);
      //   } catch (error) {
      //     Toast("Something went wrong", true);
      //     console.error("Error during saving email:", error);
      //     setGoogleLoader(false);
      //   }
      // }
      setGoogleLoader(false);
    })
    .catch((error) => {
      console.error("Error during sign in:", error);
      Toast("Something Went Wrong", true);
      setGoogleLoader(false);
    });
};

export const handleGoogleLogin_NEW = (
  setGoogleLoader,
  setUser,
  getUserDetails,
  promotionalEmail,
  updateEmail,
  setSignUpStep,
  setSignupViaGoogle
) => {
  setGoogleLoader(true);
  //setSignUpStep(2);

  signInWithPopup(auth, provider)
    .then(async (result) => {
      // setUser(result.user); // Set the user object on successful login
      let name = result.user.displayName.split(" ");
      console.log("result", result);
      const userData = {
        email: result?.user?.email,
        firstName: name[0],
        lastName: name[1],
        authType: "Google",
        plan: null,
        promoCode: null,
        isAdult: true,
        firebaseId: result?.user?.uid,
        promotionalEmail,
        productUpdateEmail: updateEmail,
      };

      setSignUpStep(2);
      setSignupViaGoogle(userData);
      setGoogleLoader(false);
    })
    .catch((error) => {
      console.error("Error during sign in:", error);
      Toast("Something went wrong", true);
      setGoogleLoader(false);
    });
};

export const sortPages = (bookData, setBookData) => {
  if (!bookData || !bookData?.pages) return; //javi

  const sortedPages = Object.entries(bookData.pages).sort((a, b) => {
    const pageA = parseInt(a[0].replace("page", ""), 10);
    const pageB = parseInt(b[0].replace("page", ""), 10);
    return pageA - pageB;
  });

  // Convert sorted pages back to an object
  const sortedPagesObject = sortedPages.reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});

  setBookData({ ...bookData, pages: sortedPagesObject });
};

export const getLatestError = async (bookId, errorModule, setStoryData=null) => {
  if (!bookId) return;
  
    if (setStoryData) {
      setStoryData((prevData) => ({
        ...prevData,
        gettingError: true,
      }));
    }

  const latestError = await callAPI(`api/error-log?bookId=${bookId}&module=${errorModule}`);
  if (latestError?.error !== null) {
    let errorMsg = "Unexpected Error Occurred. Please Try Again!";

    if (latestError?.error?.errorStack?.includes("against our community standards"))
      errorMsg =
        "Sorry! Our AI moderator thinks this prompt is probably against our community standards.  Please correct your illustration description.";
    Toast(errorMsg, true);
  }
  if (setStoryData) {
    setStoryData((prevData) => ({
      ...prevData,
      gettingError: false,
    }));
  }
};

export const getCharacterImage = async (
  bookId,
  setLoaders,
  loaders,
  setCurrentPortrait,
  setCharacterOutlineByUser,
  setCurrentPortraitList,
  setCharacterGenerationProgress,
  setStartInterval,
  bookData,
  setStoryData = null,
  storyData = null,
  errorModule = ERROR_MODULE_CHARACTER_GENERATION
) => {
  try {
    // setCharacterGenerationProgress(0);
    setLoaders({ ...loaders, characterGenerating: true });
    // const interval = setInterval(async () => {
    try {
      // setLoaders({ ...loaders, characterGenerating: true });
      // const bookDetail = await callAPI(`api/getBookDetails/${bookId}`);
      // console.log("illustrationCharacter ", illustrationCharacter);
      // if (illustrationCharacter) {
      //   setCurrentPortrait(illustrationCharacter?.pageData?.imagePromptCharacterUrl);
      //   // setCurrentPortraitList(illustrationCharacter?.pageData?.images);
      // } else
      if (bookData) {
        if (bookData?.characterPortrait) {
          if (storyData?.illustrationCharacter?.pageData?.imagePromptCharacterUrl)
            setCurrentPortrait(storyData?.illustrationCharacter?.pageData?.imagePromptCharacterUrl);
          else setCurrentPortrait(bookData?.characterPortrait || "");

          // const characterOutliner = bookData?.characterOutlineByUser || {};
          const characterOutliner = bookData?.characterOutlineByUser;

          setCharacterOutlineByUser(characterOutliner);
          setCurrentPortraitList(bookData?.currentCharacterPortraitList || []);
          setLoaders({ ...loaders, characterGenerating: false, characterRegenerate: false });
          // console.log("IS IMAAGE PROCESSING", bookData?.isImageProcessing);
          if (bookData?.isImageProcessing === true) setCharacterGenerationProgress(bookData?.progress);
          else {
            setCharacterGenerationProgress(0);

            if (setStoryData)
              setStoryData((prevData) => ({
                ...prevData,
                characterBuilding: false,
              }));
          }

          setStartInterval(false);
          // clearInterval(interval); // Clear the interval if character portrait found
        }
        //if (bookData?.progress || bookData?.error) {
        if (bookData?.progress){
          setCharacterGenerationProgress(bookData?.progress);
          // getLatestError(bookId, errorModule);
          /*
          const latestError = await callAPI(`api/error-log?bookId=${bookId}&module=${errorModule}`);
          if (latestError?.error !== null) {
            let errorMsg = "Unexpected Error Occurred. Please Try Again!";

            if (latestError?.error?.errorStack.includes("against our community standards"))
              errorMsg =
                "Sorry! Our AI moderator thinks this prompt is probably against our community standards.  Please correct your illustration description.";
            Toast(errorMsg, true);
            // setLoaders({ ...loaders, characterGenerating: false, characterRegenerate: false });
            // clearInterval(interval); // Clear the interval if character portrait found
          }//*/
        }
        if(bookData?.error) {
          getLatestError(bookId, errorModule);
          setStoryData({
            ...storyData,
            creditsPerBookLimit: parseInt(storyData?.creditsPerBookLimit) + DEDUCT_CREDITS_PER_IMAGE,
          });
        } 
      }
      setLoaders({ ...loaders, characterGenerating: false, characterRegenerate: false });
      
    } catch (error) {
      console.log("Error checking character portrait:", error.message);
      setLoaders({ ...loaders, characterGenerating: false, characterRegenerate: false });
     setStoryData((prevData) => ({
       ...prevData,
       characterBuilding: false,

     }));
    }
    //}, 5000); // Polling every 20 seconds

    //}
  } catch (error) {
    console.log(error.message);
  }
};

export const handleSubmitStepTwo = async (
  bookId,
  selectedFile,
  artStyle,
  characterOutlineByUser,
  setLoaders,
  loaders,
  setCharacterDescription,
  setUploadedImageUrl,
  setImageUploading,
  setStartInterval,

  setCharacterOutlineByUser,
  setCurrentPortrait,
  setCurrentPortraitList,
  // setCharacterGenerationProgress,
  // bookData
  continueWithoutImage = false,
  setStoryData = null,
  // illustrationCharacter = null,
  storyData = null,
  // setIllustrationCharacter = null,
) => {
  if (!bookId) {
    console.error("Missing required information: bookId or image file");
    // Toast("Please Choose Character Image", true);
    return;
  }
  if (characterOutlineByUser) {
    if (characterOutlineByUser?.characterType === CHARACTER_TYPE_HUMAN) setCharacterOutlineByUser((prevState) => ({
      ...prevState,
      character: CHARACTER_TYPE_HUMAN,
    }));
 
   }

  const characterOutline = characterOutlineByUser ? JSON.stringify(characterOutlineByUser) : {};
  let imageUrl = "";
  if (selectedFile !== "./Image holder Icon.png") imageUrl = selectedFile;
// console.log("formData", selectedFile, storyData?.uploadedImage, storyData?.illustrationCharacter?.pageData?.imagePromptCharacterUrl);
  // console.log("handleSubmitStepTwo called *", artStyle);
  const formData = new FormData();
  formData.append("image", continueWithoutImage ? null : imageUrl);
  formData.append("bookId", bookId);
  formData.append("artStyle", artStyle?.id);
  formData.append("characterOutlineByUser", characterOutline);
  formData.append("stableDifusionAPI", true);

  formData.append("continueWithoutImage", continueWithoutImage);
  if (storyData?.illustrationCharacter?.pageIndex)
    formData.append("illustrationCharacter", storyData?.illustrationCharacter?.pageIndex);
  // formData.append(
  //   "illustrationCharacter",
  //   illustrationCharacter?.pageIndex ? illustrationCharacter?.pageIndex : null
  // );
  // formData.append("illustrationCharacter", illustrationCharacter);

  // console.log("handleSubmitStepTwo called *******", formData);

  try {
    if (setStoryData)
      setStoryData((prevData) => ({
        ...prevData,
        characterBuilding: true,
      }));
    
    setLoaders({ ...loaders, characterRegenerate: true  });
    //const response = await callAPI("api/uploadImage", {formData});
    let headers = {};
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
    if (token) headers.Authorization = `Bearer ${token}`;

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/uploadImage`, {
      method: "POST",
      headers,
      body: formData,
    });

    const data = await response.json();
    if (response.ok) {
      if (data?.status === HTTP_STATUS_200) {

        // setIllustrationCharacter((prevData) => ({
        //   ...prevData,
        //   newUploaded: false,
        // }));
        setStoryData((prevData) => ({
          ...prevData,
          regeneratedNewCharacter: true
        }));
         setLoaders({ ...loaders, characterRegenerate: true });

        setCurrentPortrait("");
        setCurrentPortraitList([]);

        setCharacterDescription(data?.characterDescription);
        setUploadedImageUrl(data?.uploadedImageUrl);

        setStartInterval(true);
        setImageUploading(false);


      } else {
        if (setStoryData)
          setStoryData((prevData) => ({
            ...prevData,
            creditsPerBookLimit: parseInt(storyData?.creditsPerBookLimit) + DEDUCT_CREDITS_PER_IMAGE,
          }));
        // setLoaders({ ...loaders, characterRegenerate: false });
        setImageUploading(false);
        Toast(data?.message, true);
      }
    } else if (response.status === HTTP_STATUS_400) {
      if (setStoryData)
        setStoryData((prevData) => ({
          ...prevData,
          creditsPerBookLimit: parseInt(storyData?.creditsPerBookLimit) + DEDUCT_CREDITS_PER_IMAGE,
        }));
      Toast(data?.message, true);
      setLoaders({ ...loaders, characterRegenerate: false });
    } else {
      Toast("Unexpected Error Occurred.Please Try Again", true);
      setLoaders({ ...loaders, characterRegenerate: false });
      if (setStoryData)
        setStoryData((prevData) => ({
          ...prevData,
          creditsPerBookLimit: parseInt(storyData?.creditsPerBookLimit) + DEDUCT_CREDITS_PER_IMAGE,
        }));
    }
  } catch (error) {
    setLoaders({ ...loaders, characterRegenerate: false });
    if (setStoryData)
      setStoryData((prevData) => ({
        ...prevData,
        characterBuilding: false,
        creditsPerBookLimit: parseInt(storyData?.creditsPerBookLimit) + DEDUCT_CREDITS_PER_IMAGE,
      }));
  } finally {
    setImageUploading(false);
  }
};

export const getDraftDetails = async (
  draftBookId,
  setBookId,
  setCurrentStep,
  setShowStoryForm,
  setUploadedImageUrl,
  setCharacterDescription,
  // setStoryPages,
  setBookTitle,
  // setBookSummary,
  setStoryType,
  setChildName,
  setChildAge,
  setMaxpages,
  setPageTotal,
  setWritingStyle,
  setSelectedArtStyle,
  setStoryLanguage,
  setWordRange,
  setCurrentPortrait,
  setCurrentPortraitList,
  setCharacterOutlineByUser,
  setSelectedImage,
  setSelectedStoryConfig,
  sortPages,
  setBookData,
  storyconfig,
  setStoryTopic,
  setCurrentStoryStep = null,
  setStoryData = null
) => {
  try {
    const data = await callAPI(`api/getBookDetails/${draftBookId}`);
    // console.log("draft details", data);
    setShowStoryForm(true); //this is important when back to story from other pages

    // if (data?.book?.isDraft === true) {
    setBookId(draftBookId);



    if (
      data?.book?.step === 3 ||
      localStorage.getItem(LOCAL_STORAGE_STORY_STEP) === STORY_STEP_ILLUSTRATION_CREATE
    ) {
      setCurrentStep(3);
      setCurrentStoryStep(localStorage.getItem(LOCAL_STORAGE_STORY_STEP) || STORY_STEP_ILLUSTRATION_CREATE);
    } else {
      setCurrentStep(2);
      setCurrentStoryStep(STORY_STEP_CHARACTER_BUILD);
    }

    setShowStoryForm(true);
    setUploadedImageUrl(data?.book?.uploadedImageUrl ? data?.book?.uploadedImageUrl : "");
    setCharacterDescription(data?.book?.characterDescription ? data?.book?.characterDescription : "");
    // setStoryPages(data?.book?.pages ? data?.book?.pages : {});
    setStoryTopic(data?.book?.storyTopic ? data?.book?.storyTopic : "");
    setBookTitle(data?.book?.title ? data?.book?.title : "");
    // setBookSummary(data?.book?.summary ? data?.book?.summary : "");
    setStoryType(data?.book?.storyType || "");

    setChildName(data?.book?.childName || "");
    setChildAge(data?.book?.childAge || "");
    setMaxpages(data?.book?.pageTotal);
    setPageTotal(data?.book?.pageTotal || "");
    setSelectedStoryConfig(storyconfig[data?.book?.childAge]);

    setWritingStyle(data?.book?.writingStyle || "");

    setStoryLanguage(data?.book?.storyLanguage || "english");
    setWordRange(data?.book?.wordRange || storyconfig[data?.book?.childAge]?.words_total);
    setUploadedImageUrl(data?.book?.uploadedImageUrl || "");
    setCurrentPortrait(data?.book?.characterPortrait || "");
    setCurrentPortraitList(data?.book?.currentCharacterPortraitList || []);
    if (setStoryData) {
      setStoryData((prevData) => ({
        ...prevData,
        creditsPerBookLimit: data?.book?.creditsPerBookLimit,
      }));
    }

    if (data?.book?.artStyle) {
      const artStyleInfo = await callAPI(`api/artstyle-detail/${data?.book?.artStyle}`);
      //console.log("artStyleInfoartStyleInfo",data?.book?.artStyle, artStyleInfo);
      setSelectedArtStyle(artStyleInfo?.message.artStyle?.[0] || "");
    }
    setSelectedImage(data?.book?.uploadedImageUrl || null);
    setSelectedStoryConfig(data?.book?.storyconfig || null);

    const characterDescription = data?.book?.characterOutlineByUser
      ? JSON.parse(data?.book?.characterOutlineByUser)
      : null;
    setCharacterOutlineByUser(
      characterDescription || { characterType: CHARACTER_TYPE_HUMAN, ageUnit: CHARACTER_AGE_UNIT }
    );
    // let sample = characterDescription || { characterType: CHARACTER_TYPE_HUMAN, ageUnit: CHARACTER_AGE_UNIT };
    sortPages(data?.book ? data?.book : null, setBookData);
    // }
  } catch (error) {
    console.error("Error getting book:", error);
  }
};

export const getCharacterLibary = async (email, loaders, setLoaders, setLibrary) => {
  if (setLoaders) {
    setLoaders({ ...loaders, characterLibrary: true });
    const response = await callAPI(`api/character-library?email=${email}`);

    setLibrary(response?.data);
    setLoaders({ ...loaders, characterLibrary: true });
  }
};

export const storyExample = (type, key) => {
  // console.log("type", type, key);
  switch (type) {
    case TYPE_CHILD_AGE:
      return (
        <h1 className=" flex text-base text-storyblue m-4">
          These are the page totals available for the reading age selected
          <br />
          {key.map((obj) => obj.key).join(", ")} Pages
        </h1>
      )
    case TYPE_STORY:
      switch (key) {
        case STORY_TYPE_BEDTIME_STORY:
          return (
            <h1 className="text-base text-storyblue m-4">
              A bedtime story is a soothing tale told before sleep, often filled with whimsical characters and
              gentle morals to lull children into peaceful dreams.
            </h1>
          );
        case STORY_TYPE_FABLE:
          return (
            <h1 className="text-base text-storyblue m-4">
              A fable is a short narrative that uses animals, plants, or inanimate objects as characters to
              convey moral lessons or teach ethical principles in a simple and allegorical manner.
            </h1>
          );
        case STORY_TYPE_FAIRYTALE:
          return (
            <h1 className="text-base text-storyblue m-4">
              A fairy tale is a fantastical narrative often featuring magical elements, enchanting characters,
              and timeless themes of adventure, love, and triumph over adversity.
            </h1>
          );
        case STORY_TYPE_ADVENTURE:
          return (
            <h1 className="text-base text-storyblue m-4">
              An adventure story is a thrilling narrative that follows characters through exciting and often
              perilous journeys, brimming with action, exploration, and unexpected twists.
            </h1>
          );
        case STORY_TYPE_EDUCATIONAL:
          return (
            <h1 className="text-base text-storyblue m-4">
              An educational story is a narrative crafted to both entertain and inform, conveying knowledge or
              moral lessons through engaging characters, plots, and settings.
            </h1>
          );
        case STORY_TYPE_MYSTERY:
          return (
            <h1 className="text-base text-storyblue m-4">
              A mystery story is a suspenseful narrative that revolves around solving a puzzling crime or
              uncovering hidden truths through clues, twists, and unexpected revelations.
            </h1>
          );
        case STORY_TYPE_SCIENCE_FICTION:
          return (
            <h1 className="text-base text-storyblue m-4">
              A science fiction story is a speculative narrative set in a future or alternate reality,
              exploring imaginative concepts, advanced technology, and extraterrestrial phenomena to
              contemplate the possibilities of science and society.
            </h1>
          );
        case STORY_TYPE_REALISTIC_FICTION:
          return (
            <h1 className="text-base text-storyblue m-4">
              A realistic fiction story is a narrative grounded in everyday life and plausible events,
              focusing on relatable characters, situations, and themes to explore the complexities of human
              experience.
            </h1>
          );
        default:
          return null;
      }
    case TYPE_WRITING_STYLE:
      switch (key) {
        case WRITING_STYLE_IMAGINATIVE:
          return (
            <h1 className="text-base text-storyblue m-4">
              Imaginative writing whisks readers to vibrant worlds with vivid descriptions, inventive
              language, and unconventional storytelling, sparking creativity and captivating imaginations.
            </h1>
          );
        case WRITING_STYLE_FUNNY:
          return (
            <h1 className="text-base text-storyblue m-4">
              Funny writing uses wit, humor, and clever wordplay to make readers laugh, infusing stories with
              comedic elements and playful storytelling techniques.
            </h1>
          );
        case WRITING_STYLE_HEART_WARMING:
          return (
            <h1 className="text-base text-storyblue m-4">
              User create a shorter description of funny writing style.
            </h1>
          );
        case WRITING_STYLE_ACTION_PACKED:
          return (
            <h1 className="text-base text-storyblue m-4">
              The action-packed writing style thrills readers with dynamic pacing, vivid descriptions, and
              fast-paced plot twists, ensuring constant excitement and momentum.
            </h1>
          );
        case WRITING_STYLE_NOSTALGIC:
          return (
            <h1 className="text-base text-storyblue m-4">
              The nostalgic writing style stirs longing for the past with vivid details and a wistful tone,
              taking readers on sentimental journeys to cherished memories and bygone eras.
            </h1>
          );
        case WRITING_STYLE_EMPOWERING:
          return (
            <h1 className="text-base text-storyblue m-4">
              Empowering writing inspires resilience, strength, and positive change, motivating readers to
              overcome challenges and embrace their potential for transformation in their lives and
              communities.
            </h1>
          );
        case WRITING_STYLE_SPOOKY:
          return (
            <h1 className="text-base text-storyblue m-4">
              Spooky writing chills with eerie descriptions, suspenseful pacing, and supernatural elements,
              immersing readers in macabre tales that evoke fear and fascination.
            </h1>
          );
        case WRITING_STYLE_EDUCATIONAL:
          return (
            <h1 className="text-base text-storyblue m-4">
              Educational writing enlightens with clear explanations, engaging examples, and structured
              content, simplifying complex subjects for accessibility while ensuring readability.
            </h1>
          );
        default:
          return null;
      }
     
    case TYPE_ART_STYLE:
      return (
        <img
          src={key?.sref_image}
          alt="Art Style"
          className="w-[15rem] h-[15rem] md:w-[20rem] md:h-[20rem] rounded-lg"
        />
      );
      default: break;
  }
};

export const resendVerificationEmail = async (loaders, setLoaders, email, firstName) => {
  setLoaders({ ...loaders, verificationEmail: true });
  const response = await callAPI(`user/resend-verification-email`, { email, firstName });
  if (response.status) Toast("Verification Email Sent Successfully!");
  else Toast("Verification Email Sent Failed!", true);
  setLoaders({ ...loaders, verificationEmail: false });
};

export const handleCouponChange = debounce(async (couponCode, amount, requestType) => {
  try {
    if (couponCode?.length >= 3) {
      // setCouponValidationLoading(true);
      const response = await callAPI("user/coupon-validate", {
        couponCode,
        amount,
        requestType,
      });

      if (response.status === HTTP_STATUS_200) {
        // setDiscount(response?.data);
        Toast("Coupon Applied");
      } else {
        Toast("Invalid Coupon", true);
      }
    }
  } catch (error) {
    console.error("Error validating coupon:", error);
  } finally {
    // setCouponValidationLoading(false);
  }
}, 500);

export const saveCharacterForImagePrompt = async (bookId, characterUrl, pageIndex, characterOutlineByUser,uploadedImage) => {
  try {
    //console.log("bookId, characterUrl, pageIndex", bookId, characterUrl, pageIndex);
    await callAPI("api/book-update/update-page-characterPrompt-url", {
      characterUrl,
      bookId,
      pageIndex,
      characterOutlineByUser,
      uploadedImage,
    });
  } catch (error) {
    console.log(error);
  }
};

export const checkUserBookCreationStatus = async (email) => {
  const response = await callAPI("user/check-user-book-creation-status", { email });
  // console.log(response, "ressssss");
  if (response?.status === HTTP_STATUS_200) return true;
  else if (response?.status === HTTP_STATUS_403) return false;
  else Toast("Something went wrong!", true);
};

//export const editStory = async (book, setDraftId, setIllustrationCharacter, navigateTo) => {
  export const editStory = async (book, setDraftId, setStoryData, navigateTo) => {
    try {
      const data = await callAPI("api/make-story-draft", {
        email: book.email,
        bookId: book.id || book?.bookId,
      });

      if (data) {
        const currentBookId = localStorage.getItem(LOCAL_STORAGE_BOOK_ID);
        // if (currentBookId !== book.id) setIllustrationCharacter(null);
        if (currentBookId !== book.id)
          setStoryData((prev) => ({
            ...prev,
            illustrationCharacter: null,
          }));

        localStorage.removeItem(LOCAL_STORAGE_BOOK_ID); //localStorage.setItem(LOCAL_STORAGE_BOOK_ID, book.id);
        localStorage.removeItem(LOCAL_STORAGE_STORY_STEP);
        localStorage.removeItem(LOCAL_STORAGE_EDIT_MODE);
        setDraftId(data.bookId);
        navigateTo("/");
      }
    } catch (error) {}
  };

export const archiveBook = async (bookId, fetchBooks, setLoader = null) => {
  try {
    if (setLoader) setLoader(true);
    const response = await callAPI("api/book-update/archive-book", { bookId });
    if (response.status === HTTP_STATUS_200) {
      Toast(response?.message);
      fetchBooks();
    } else Toast(response?.message || response?.error, true);
    if (setLoader) setLoader(false);
  } catch (error) {
    Toast("Something Went Wrong!", true);
    if (setLoader) setLoader(false);
  }
};

export const unArchiveBook = async (bookId, fetchBooks, setLoader = null) => {
  try {
    if (setLoader) setLoader(true);
    const response = await callAPI("api/book-update/unarchive-book", { bookId });
    if (response.status === HTTP_STATUS_200) {
      Toast(response?.message);
      fetchBooks();
    } else Toast(response?.message || response?.error, true);

    if (setLoader) setLoader(false);
  } catch (error) {
    Toast("Something Went Wrong!", true);
    if (setLoader) setLoader(false);
  }
};

export const handleDeleteBook = async (bookId, setLoader, navigateTo) => {
  setLoader(true);
  const response = await callAPI(`api/delete-book`, { bookId });
  if (response.status === 200) {
    localStorage.removeItem(LOCAL_STORAGE_BOOK_ID);
    localStorage.removeItem(LOCAL_STORAGE_EDIT_MODE);
    //  setCurrentStep(0);
    Toast("Book Deleted Successfully");
    // navigateTo(-1);
    window.location.reload(); // Refresh the app
    setLoader(false);
  } else {
    Toast("Unexpected Error deleting book", true);
    setLoader(false);
  }
};

export const checkSingleBookPlan = async (planId, setSingleBookPlan) => {
  try {
    const response = await callAPI(`plans/check-single-book-plan/?planId=${planId}`);
    if (response.status === HTTP_STATUS_200 && response?.message === true) {
      setSingleBookPlan(true);
    } else {
      setSingleBookPlan(false);
    }
  } catch (error) {
    console.log("something went wrong", error);
  }
};


export const updateBookForFreeMode = async (bookId) => {
  try {
    const response = await callAPI(`api/book-update/update-book-free-mode`, { bookId });
    console.log("response", response);
    if (response.status === HTTP_STATUS_200 ) {
      // setSingleBookPlan(true);
      Toast("Free Mode Activated");
    } else {
     Toast("Free Mode failed! Try Again",true);
    }
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};


export const  convertSecondsToMinutes=(seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes} : ${remainingSeconds} `;
}