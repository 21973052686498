import React, { useEffect, useState } from "react";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";

import { Toast } from "../../../components/Common/Toast";
import SubmitButton from "../../../components/Common/SubmitButton";
import { callAPI } from "../../../services/apiService";
import { HTTP_STATUS_200, MID_JOURNEY_MODE_FAST, MID_JOURNEY_MODE_RELAX } from "../../../helper/Constant";
import TableHeader from "../../../components/Common/TableHeader";
import { NoRecordFound } from "../../../components/Common/NoRecordFound";
import { convertTimestampToDateTime, getCurrentTimestamp } from "../../../helper/helper";
import Loader from "../../../components/Common/Loader";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PlanForm = ({ setShowForm, planDetails, getPlans, setPlans, setPlanDetails }) => {
  const [loader, setLoader] = useState(false);
  const [bookPages, setBookPages] = useState([]);
  const [costHistory, setCostHistory] = useState([]);
  const [newCostLoader, setNewCostLoader] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Plan Name is required"),
    cost: !planDetails ? Yup.number().required("Price is required") : Yup.number(),

    chargeCycle: Yup.string().required("Charge Cycle is required"),
    storyCreationLimit: Yup.number()
      // .min(0, "Story Creation Limit must be at least 0")
      .required("Story Creation Limit is required"),
    imageCredits: Yup.number().required("Image Credits is required"),
    additionalImageCredits: Yup.number().required("Additional Image Credits is required"),
    additionalImageCreditsCost: Yup.number().required("Additional Image Credits Cost is required"),
    maxBookPage: Yup.number().required("Maxiumum Book Page is required"),
    // additionalStoryCreationLimit: Yup.number().required("Additional Story Creation Limit is required"),
    // additionalStoryCreationCost: Yup.number().required("Additional Story Creation Cost is required"),
    modeCharacterGeneration: Yup.string().required("Character Generation Mode is required"),
    modeIllustrationGeneration: Yup.string().required("Illustration Generation Mode is required"),
    maintenanceMode: Yup.mixed().required("Maintenance Mode is required"),
    // freePhysicalBookStatus: Yup.boolean().required("Physical Book status is required"),
    // freePhysicalBookQuantity: Yup.number()
    //   .positive("Quantity must be a positive number")
    //   .when("freePhysicalBookStatus", {
    //     is: true, // when freePhysicalBookStatus is true
    //     then: Yup.number()
    //       .required("Quantity is required when Physical Book status is true")
    //       .positive("Quantity must be a positive number"),
    //   }),
    freePhysicalBookStatus: Yup.mixed()
      .oneOf([true, false], "Physical Book status must be true or false")
      .required("Physical Book status is required"),
    freePhysicalBookQuantity: Yup.number()
      .positive("Quantity required")
      .integer("Quantity must be an integer")
      .when("freePhysicalBookStatus", {
        is: true,
        then: (schema) => schema.required("Quantity is required when Physical Book status is true"),
        otherwise: (schema) => schema.notRequired(),
      }),
  });

  const formik = useFormik({
    initialValues: {
      planId: null,
      name: "",
      cost: "",
      chargeCycle: "",
      storyCreationLimit: "",
      imageCredits: "",
      additionalImageCredits: "",
      additionalImageCreditsCost: "",
      maxBookPage: "",
      additionalStoryCreationLimit: "",
      additionalStoryCreationCost: "",
      modeCharacterGeneration: "",
      modeIllustrationGeneration: "",
      maintenanceMode: false,
      trialDays: 0,
      freePhysicalBookStatus: false,
      freePhysicalBookQuantity: 1,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const bookPageList = () => {
    let newOptions = [];

    for (let i = 16; i <= 52; i = i + 4) {
      newOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    setBookPages(newOptions);
  };

  // console.log("formikk val", formik.values);
  const handleSubmit = async (e) => {
    let response;
    try {
      setLoader(true);
      if (!planDetails) {
        response = await callAPI("admin/plan/save", {
          name: formik.values.name,
          cost: formik.values?.cost,
          chargeCycle: formik.values?.chargeCycle,
          storyCreationLimit: formik.values.storyCreationLimit,
          imageCredits: formik.values.imageCredits,
          additionalImageCredits: formik.values.additionalImageCredits,
          additionalImageCreditsCost: formik.values.additionalImageCreditsCost,
          maxBookPage: formik.values.maxBookPage,
          additionalStoryCreationLimit: formik?.values?.additionalStoryCreationLimit,
          additionalStoryCreationCost: formik?.values?.additionalStoryCreationCost,
          modeCharacterGeneration: formik?.values?.modeCharacterGeneration,
          modeIllustrationGeneration: formik?.values?.modeIllustrationGeneration,
          maintenanceMode: formik?.values?.maintenanceMode,
          trialDays: formik?.values?.trialDays,
          freePhysicalBookStatus: formik?.values?.freePhysicalBookStatus,
          freePhysicalBookQuantity: formik?.values?.freePhysicalBookQuantity,
        });
      } else {
        response = await callAPI("admin/plan/save", {
          planId: formik.values.planId,
          name: formik.values.name,
          // cost: formik.values?.cost,
          chargeCycle: formik.values?.chargeCycle,
          storyCreationLimit: formik.values.storyCreationLimit,
          imageCredits: formik.values.imageCredits,
          additionalImageCredits: formik.values.additionalImageCredits,
          additionalImageCreditsCost: formik.values.additionalImageCreditsCost,
          maxBookPage: formik.values.maxBookPage,
          additionalStoryCreationLimit: formik?.values?.additionalStoryCreationLimit,
          additionalStoryCreationCost: formik?.values?.additionalStoryCreationCost,
          modeCharacterGeneration: formik?.values?.modeCharacterGeneration,
          modeIllustrationGeneration: formik?.values?.modeIllustrationGeneration,
          maintenanceMode: formik?.values?.maintenanceMode,
          trialDays: formik?.values?.trialDays,
          freePhysicalBookStatus: formik?.values?.freePhysicalBookStatus,
          freePhysicalBookQuantity: formik?.values?.freePhysicalBookQuantity,
        });
      }
      setLoader(false);
      console.log("message", response?.message, "ERRORRRRRR", response?.error);
      if (response?.status !== HTTP_STATUS_200) Toast(response?.message || response?.error, true);
      else {
        Toast("Plan Saved Successfully");
        formik.resetForm();
        getPlans(setPlans);
        setShowForm(false);
      }
    } catch {
      Toast("Plan Failed To Save", true);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (planDetails) {
      const values = {
        planId: planDetails?.id,
        name: planDetails?.name,
        cost: "",
        chargeCycle: planDetails?.chargeCycle,
        storyCreationLimit: planDetails?.storyCreationLimit,
        imageCredits: planDetails?.credits,
        additionalImageCredits: planDetails?.additionalAICredit?.credits,
        additionalImageCreditsCost: planDetails?.additionalAICredit?.price,
        maxBookPage: planDetails?.maxBookPage,
        additionalStoryCreationLimit: planDetails?.additionalAICredit?.storyCreationLimit,
        additionalStoryCreationCost: planDetails?.additionalAICredit?.storyCreationCost,
        modeCharacterGeneration: planDetails?.modeCharacterGeneration || "",
        modeIllustrationGeneration: planDetails?.modeIllustrationGeneration || "",
        maintenanceMode: planDetails?.maintenanceMode || false,
        trialDays: planDetails?.trialDays || 0,
        freePhysicalBookStatus: planDetails?.freePhysicalBookStatus || false,
        freePhysicalBookQuantity: planDetails?.freePhysicalBookQuantity || 0,
      };
      formik.setValues(values);
      if (planDetails?.costHistory) setCostHistory(planDetails?.costHistory);
      else {
        setCostHistory([
          {
            stripe_plan_id: planDetails?.stripe_plan_id,
            cost: planDetails?.cost,
            timestamp: planDetails?.timestamp,
          },
        ]);
      }
    }
  }, [planDetails]);

  useEffect(() => {
    bookPageList();
  }, []);

  const getUpdatedPlanDetails = async () => {
    try {
      const response = await callAPI(`plans/getPlans`);
      if (response && response.plans) {
        let selectedPlan = response?.plans?.filter((item) => item.id === planDetails?.id);
        console.log("selectedPlan", selectedPlan);
        setPlanDetails(selectedPlan[0]);
        setCostHistory(selectedPlan[0]?.costHistory);
        // setPlans(response.plans);
      }
      // getPlans(setPlans)
      //     const response = await callAPI(`admin/plan/get-plan-cost-history?planId=${planDetails?.id}  `);
      //     if (response?.status === HTTP_STATUS_200) {
      //       console.log("response?.message", response?.message, planDetails);
      //       setCostHistory(response?.message);
      //     }
    } catch (error) {}
  };

  const handleAddNewCost = async () => {
    try {
      setNewCostLoader(true);

      const response = await callAPI("admin/plan/add-plan-new-cost", {
        planId: planDetails?.id,
        newCost: formik?.values?.cost,
      });

      if (response?.status === HTTP_STATUS_200) {
        setCostHistory([...costHistory, { cost: formik?.values?.cost, timestamp: getCurrentTimestamp() }]);
        await getUpdatedPlanDetails();
        Toast("New Cost Added Successfully");

        // getPlans(setPlans);
        // setShowForm(false);
        formik.values.cost = "";
      } else Toast(response?.message, true);
      setNewCostLoader(false);
    } catch (error) {
      setNewCostLoader(false);
      Toast("Something went wrong! Try again!", true);
    }
  };
  // console.log(formik.values, "planDetails");
  return (
    <div className="bg-storytan mx-auto">
      <div className="max-w-7xl mx-auto bg-storytan ">
        {planDetails ? (
          <h1 className="text-storyblue text-xl font-bold text-start mb-4">Edit Plan</h1>
        ) : (
          <h1 className="text-storyblue text-xl font-bold text-start mb-4">Create New Plan</h1>
        )}
        <form
          id="form-admin-plans"
          name="form-admin-plans"
          className="text-start bg-storytan"
          onSubmit={formik.handleSubmit}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-x-2 md:gap-y-[.25rem]">
            <div className="flex flex-col gap-2">
              <label className="font-medium">Plan Name</label>
              <input
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Plan Name"
                className="p-2 w-full rounded-md"
                autoFocus={true}
              />
              <div className="text-red-600 mt-1">{formik.errors.name}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium">Charging Cycle</label>
              <select
                name="chargeCycle"
                value={formik.values.chargeCycle}
                onChange={formik.handleChange}
                className="p-2 w-full rounded-md"
                disabled={planDetails}
              >
                <option value="">Select Cycle</option>
                <option value={"one_time"}>One Time</option>
                <option value={"month"}>Monthly Recurring</option>
                <option value={"year"}>Yearly Recurring</option>
              </select>
              <div className="text-red-600 mt-1">{formik.errors.chargeCycle}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor="storyCreationLimit">
                Story Creation Limit
              </label>
              <input
                type="number"
                id="storyCreationLimit"
                name="storyCreationLimit"
                value={formik.values.storyCreationLimit}
                onChange={formik.handleChange}
                placeholder="Story Creation Limit"
                className={`p-2 w-full rounded-md 
                  "bg-white "
                `}
              />
              <div className="text-red-600 mt-1">{formik.errors.storyCreationLimit}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor="storyCreationLimit">
                Image Credits Per Book
              </label>
              <input
                type="number"
                id="imageCredits"
                name="imageCredits"
                min={1}
                value={formik.values.imageCredits}
                onChange={formik.handleChange}
                placeholder="Image Credits Per Book"
                className={`p-2 w-full rounded-md ${
                  planDetails?.imageCredits ? "bg-white text-[#9EA4B0]" : ""
                }`}
              />
              <div className="text-red-600 mt-1">{formik.errors.imageCredits}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor="storyCreationLimit">
                Additional Image Credits Per Book
              </label>
              <input
                type="number"
                id="additionalImageCredits"
                name="additionalImageCredits"
                min={1}
                value={formik.values.additionalImageCredits}
                onChange={formik.handleChange}
                placeholder="Additional Image Credits Per Book"
                className={`p-2 w-full rounded-md ${
                  planDetails?.additionalImageCredits ? "bg-white text-[#9EA4B0]" : ""
                }`}
              />
              <div className="text-red-600 mt-1">{formik.errors.additionalImageCredits}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor="storyCreationLimit">
                Additional Image Credits Cost Per Book ($)
              </label>
              <input
                type="text"
                id="additionalImageCreditsCost"
                name="additionalImageCreditsCost"
                min={1}
                value={formik.values.additionalImageCreditsCost}
                onChange={formik.handleChange}
                placeholder="Additional Image Credits Cost Per Book"
                className={`p-2 w-full rounded-md ${
                  planDetails?.additionalImageCreditsCost ? "bg-white text-[#9EA4B0]" : ""
                }`}
              />
              <div className="text-red-600 mt-1">{formik.errors.additionalImageCreditsCost}</div>
            </div>
            {/* //new */}
            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor="storyCreationLimit">
                Additional Story Creation Limit
              </label>
              <input
                type="number"
                id="additionalStoryCreationLimit"
                name="additionalStoryCreationLimit"
                min={1}
                value={formik.values.additionalStoryCreationLimit}
                onChange={formik.handleChange}
                placeholder="Additional Story Creation Limit"
                className={`p-2 w-full rounded-md ${
                  planDetails?.additionalStoryCreationLimit ? "bg-white text-[#9EA4B0]" : ""
                }`}
              />
              {/* <div className="text-red-600 mt-1">{formik.errors.additionalStoryCreationLimit}</div> */}
            </div>

            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor="storyCreationLimit">
                Additional Story Creation Cost ($)
              </label>
              <input
                type="text"
                id="additionalStoryCreationCost"
                name="additionalStoryCreationCost"
                min={1}
                value={formik.values.additionalStoryCreationCost}
                onChange={formik.handleChange}
                placeholder="Additional Story Creation Cost"
                className={`p-2 w-full rounded-md ${
                  planDetails?.additionalStoryCreationCost ? "bg-white text-[#9EA4B0]" : ""
                }`}
              />
              {/* <div className="text-red-600 mt-1">{formik.errors.additionalStoryCreationCost}</div> */}
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor="storyCreationLimit">
                Maximum Book Pages
              </label>
              <select
                id="maxBookPage"
                name="maxBookPage"
                className="row-start-1 col-start-1 w-full rounded-md text-sm md:text-xl p-2 appearance-none"
                value={formik.values.maxBookPage}
                onChange={formik.handleChange}
                placeholder="Max Book Pages"
              >
                <option value="" disabled>
                  Please Select Page Total
                </option>
                {bookPages}
              </select>

              <div className="text-red-600 mt-1">{formik.errors.maxBookPage}</div>
            </div>

            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor="modeCharacterGeneration">
                Character Generation Mode
              </label>
              <select
                id="modeCharacterGeneration"
                name="modeCharacterGeneration"
                className="row-start-1 col-start-1 w-full rounded-md text-sm md:text-xl p-2 appearance-none"
                value={formik.values.modeCharacterGeneration}
                onChange={formik.handleChange}
                placeholder="Character Generation Mode"
              >
                <option value="">Please Select Mode</option>
                <option value={MID_JOURNEY_MODE_FAST}>Fast</option>
                <option value={MID_JOURNEY_MODE_RELAX}>Relaxed</option>
              </select>

              <div className="text-red-600 mt-1">{formik.errors.modeCharacterGeneration}</div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor="modeIllustrationGeneration">
                Illustration Generation Mode
              </label>
              <select
                id="modeIllustrationGeneration"
                name="modeIllustrationGeneration"
                className="row-start-1 col-start-1 w-full rounded-md text-sm md:text-xl p-2 appearance-none"
                value={formik.values.modeIllustrationGeneration}
                onChange={formik.handleChange}
                placeholder="Illustration Generation Mode"
              >
                <option value="">Please Select Mode</option>
                <option value={MID_JOURNEY_MODE_FAST}>Fast</option>
                <option value={MID_JOURNEY_MODE_RELAX}>Relaxed</option>
              </select>

              <div className="text-red-600 mt-1">{formik.errors.modeIllustrationGeneration}</div>
            </div>

            <div className="flex flex-col gap-2">
              <label className="font-medium">{planDetails ? "New Price ($)" : "Price ($)"}</label>
              <input
                type="number"
                name="cost"
                value={formik.values.cost}
                onChange={formik.handleChange}
                placeholder={planDetails ? "New Price" : "Price"}
                className="p-2 w-full rounded-md"
                // readOnly={formik?.values?.cost == planDetails?.cost?.toFixed(2)}
              />
              <div className="text-red-600 mt-1">{formik.errors.cost}</div>
            </div>
            {/* trial period input */}
            <div className="flex flex-col gap-2">
              <label className="font-medium">Trial Days</label>
              <input
                type="number"
                name="trialDays"
                value={formik.values.trialDays}
                onChange={formik.handleChange}
                placeholder="Trial Peroid"
                className="p-2 w-full rounded-md bg-white disabled:bg-gray-200 disabled:text-gray-500"
                disabled={!!planDetails}
                // readOnly={formik?.values?.cost == planDetails?.cost?.toFixed(2)}
              />
              <div className="text-red-600 mt-1">{formik.errors.trialDays}</div>
            </div>
            <div className="flex flex-row gap-2 justify-between ">
              <div className="flex flex-col gap-2  ml-2  ">
                <label className="font-medium">Maintainace Mode: </label>
                <FontAwesomeIcon
                  icon={formik.values.maintenanceMode ? faToggleOn : faToggleOff}
                  className={`h-8 w-8 cursor-pointer ${
                    formik.values.maintenanceMode ? "text-storygreen" : "text-red-500"
                  } `}
                  title={formik.values.maintenanceMode ? "Deactivate " : "Activate "}
                  onClick={() => {
                    formik.setFieldValue("maintenanceMode", !formik.values.maintenanceMode);
                  }}
                />
              </div>

              <div className="flex flex-col gap-2  ml-2">
                <label className="font-medium"> Free Physical Book: </label>
                <FontAwesomeIcon
                  icon={formik.values.freePhysicalBookStatus ? faToggleOn : faToggleOff}
                  className={`h-8 w-8 cursor-pointer ${
                    formik.values.freePhysicalBookStatus ? "text-storygreen" : "text-red-500"
                  } `}
                  // onChange={formik.handleChange}
                  title={formik.values.freePhysicalBookStatus ? "Deactivate " : "Activate "}
                  onClick={() => {
                    formik.setFieldValue("freePhysicalBookStatus", !formik.values.freePhysicalBookStatus);
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 mr-2">
                <label className="font-medium">Free Books Quantity</label>
                <input
                  type="number"
                  name="freePhysicalBookQuantity"
                  value={formik.values.freePhysicalBookQuantity}
                  onChange={formik.handleChange}
                  placeholder="Free Books Quantity"
                  className="p-2 w-full rounded-md bg-white disabled:bg-gray-200 disabled:text-gray-500"
                  disabled={formik.values.freePhysicalBookStatus === false}
                  // readOnly={formik?.values?.cost == planDetails?.cost?.toFixed(2)}
                />
                <div className="text-red-600 mt-1">{formik.errors.freePhysicalBookQuantity}</div>
              </div>
            </div>

            {planDetails && (
              <button
                type="button"
                onClick={() => {
                  handleAddNewCost();
                }}
                disabled={!formik?.values?.cost || formik?.values?.cost?.length < 1}
                className={` ${
                  formik?.values?.cost?.length < 1 || newCostLoader ? "opacity-[50%]" : ""
                } mt-8 flex justify-center gap-2 bg-storyblue text-white font-bold rounded-md text-base h-10 items-center w-52`}
              >
                {planDetails ? "Add New Cost" : " Add Cost"} <Loader loader={newCostLoader} />
              </button>
            )}
          </div>

          {planDetails && costHistory && (
            <div>
              <h3 className=" my-2 font-bold">Plan Price History </h3>
              <div className=" h-[135px] overflow-y-scroll">
                <table className="min-w-full border border-gray-300 ">
                  {TableHeader(["Price", "Created At"])}

                  <tbody>
                    {costHistory && costHistory.length > 0 ? (
                      costHistory?.map((history, index) => (
                        <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                          {/* <td className="text-left py-2 px-4 border-b capitalize">{history?.stripe_plan_id}</td> */}
                          <td className="text-left py-2 px-4 border-b">
                            {"$ " + history?.cost || "-"}
                            <span className="border-b ml-2 text-sm text-storygreen italic">
                              {history?.isDefault === true ? "Default" : ""}
                            </span>
                          </td>
                          <td className="text-left py-2 px-4 border-b">
                            {convertTimestampToDateTime(history?.timestamp) || "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoRecordFound colSpan={4} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="flex gap-3 flex-wrap md:flex-nowrap">
            <button
              type="submit"
              className={`${loader ? "opacity-[50%]" : ""}
                  
             w-full mt-2 md:mt-[1rem] flex justify-center gap-2 bg-storyblue text-white font-bold rounded-md text-base h-11 items-center`}
              disabled={loader}
            >
              Save
              <Loader loader={loader} />
            </button>

            <button
              type="button"
              className={`w-full mt-2 md:mt-[1rem] flex justify-center bg-storygreen text-white hover:text-white font-bold rounded-md text-base p-2`}
              onClick={() => setShowForm()}
            >
              Close
            </button>
          </div>
          {/* <SubmitButton
            title="Save"
            loader={loader == true || (!planDetails && !formik?.values?.cost)}
            cancelOperation={setShowForm}
          /> */}
        </form>
      </div>
    </div>
  );
};
