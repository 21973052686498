import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { auth, provider } from "../../firebase";
import { signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import "../../App.css";
import TextModal from "../../components/Common/TextModal";
import Logo from "../Admin/logo.webp";
import LogoBlack from "../Admin/logoBlack.png";

import { useUser } from "../../context/userContext";
import { AGE_0_2, AGE_3_7, AGE_8_12 } from "../../helper/Constant";
import { callAPI } from "../../services/apiService";
import { getCurrentYear } from "../../helper/helper";

function BookPage({bookIdAdmin = null}) {
  const { user } = useUser();
  const [totalPages, setTotalPages] = useState(0);
  const [bookData, setBookData] = useState(null);
  const { bookId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fullText, setFullText] = useState("");
  const [selectedPageDetails, setSelectedPageDetails] = useState(null);
  const [storyText, setStoryText] = useState(null);
  const [storyPhoto, setStoryPhoto] = useState(null);
  const [showFullText, setShowFullText] = useState(false);
  const handleToggleFullText = () => {
    setShowFullText(!showFullText);
  };
  const handleOpenModal = (pageKey) => {
    const [selectedPageKey, selectedPageDetails] = sortedPages().find(([key, details]) => key === pageKey);
    setSelectedPageDetails({
      key: selectedPageKey,
      details: selectedPageDetails,
    });
    setFullText(selectedPageDetails?.text);
    setIsModalOpen(true);
    // setFullText(text);
    // setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  // Fetch book data

  useEffect(() => {
    const fetchBookData = async () => {
      try {
        const bookID = bookIdAdmin ? bookIdAdmin : bookId ? bookId : "";
        if (bookID) {
          const data = await callAPI(`api/books/${bookID}`);
          // const response = await fetch(`${process.env.REACT_APP_API_URL}/api/books/${bookID}`);
          // if (response.ok) {
          //   const data = await response.json();
          setBookData(data);
          // conssole.log("book data update in useeffect", data);
          setTotalPages(Object.keys(data.pages).length * 2 + 1);
          // } else {
          //   console.error("Failed to fetch book data");
          // }
        } else console.log("bookId missing");
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchBookData();
  }, [bookId, bookIdAdmin]);

  useEffect(() => {
    if (bookData) {
      const firstPageKey = `page1`;
      const firstPageDetails = bookData.pages[firstPageKey];
      setStoryText(firstPageDetails?.text);
      setStoryPhoto(firstPageDetails?.imageURLwebp || firstPageDetails?.imageURL);
    }
  }, [bookData]);
  if (!bookData) {
    return (
      <div>
       {!bookIdAdmin && <NavigationBar />}
        <div className="h-[80vh] flex justify-center items-center">
          <img className="w-16 h-16" src="/loading.gif" alt="Loading" />
        </div>
      </div>
    );
  }
  // Function to count the number of pages with images
  const countPagesWithImages = () => {
    return Object.values(bookData.pages).filter((page) => page.imageURL).length;
  };
  // Extract page numbers and sort them
  const sortedPages = () => {
    return Object.entries(bookData.pages).sort(([pageKey1], [pageKey2]) => {
      const pageNum1 = parseInt(pageKey1.match(/\d+/)[0]);
      const pageNum2 = parseInt(pageKey2.match(/\d+/)[0]);
      return pageNum1 - pageNum2;
    });
  };
  const renderImageTracker = () => {
    if (!bookData) return null; // Ensure bookData is available
    const totalPages = bookData?.pageTotal; // Or dynamically determine the number of pages
    let pagesWithImages = 0;
    let cells = [];
    for (let i = 1; i <= totalPages; i++) {
      const pageKey = `page${i}`;
      const pageData = bookData.pages[pageKey];
      const pageHasImage = pageData && pageData.imageURL;
      if (pageHasImage) {
        pagesWithImages++;
      }
      const cellClass = pageHasImage ? "bg-storyblue rounded-md" : "bg-gray-300";
      const textColor = pageHasImage ? "text-white" : "text-gray-700";
      cells.push(
        <div
          key={i}
          className={`h-8 w-full ${cellClass} ${textColor} m-0.5 flex justify-center items-center text-xs cursor-pointer`}
          onClick={() => {
            // console.log(pageData);
            setStoryText(pageData?.text);
            setStoryPhoto(pageData?.imageURLwebp || pageData?.imageURL);
          }}
        >
          {i}
        </div>
      );
    }
    // Check if all pages have images
    if (pagesWithImages === totalPages) {
      return (
        <Link
          to={`/bookflip/${bookId}`}
          className="bg-storyblue rounded-md text-white text-center p-2 block font-bold"
        >
          View in FlipBook Mode
        </Link>
      );
    } else {
      return <div className="grid grid-cols-8 gap-1 w-full">{cells}</div>;
    }
  };
  return (
    <div>
      {!bookIdAdmin && <NavigationBar />}
      <div className="bg-storytan p-4 mx-auto">
        {/* <h1 className="rounded-md uppercase bg-storyblue text-storytan p-4 font-serif text-xl md:text-4xl font-bold my-2">
          {bookData.title}
        </h1> */}
        {/* <div className=" text-center my-2">
          <div className="image-tracker">{renderImageTracker()}</div>
        </div> */}
        {/* <div className="min-h-[60vh] flex mt-4 justify-evenly">
          <p className="leading-normal w-[55%] text-justify p-3 font-bold font-serif">
            {showFullText ? storyText : `${storyText?.slice(0, 1000)}... `}
            {storyText?.length > 1000 && (
              <button
                className="text-storygreen cursor-pointer focus:outline-none font-serif"
                onClick={handleToggleFullText}
              >
                {showFullText ? "Show Less" : "Show More"}
              </button>
            )}
          </p>
          <div
            className=" w-96 h-96"
            style={{
              backgroundImage: `url('${
                storyPhoto ? storyPhoto : "/pattern.png"
              }')`,
              backgroundSize: "cover",
            }}
          />
        </div> */}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
          {/* <div className="h-72 w-72 mx-auto">
              <div
                className="h-72 w-72 rounded-lg  bg-storyblue"
                style={{
                  backgroundImage: `url("/pattern.png")`,
                  backgroundSize: "cover",
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
            <div
              className=" flex flex-col justify-end font-serif rounded-lg"
              style={{
                backgroundImage: `url("/paper.jpg")`,
                backgroundSize: "cover",
                height: "100%",
                width: "100%",
              }}
            >
              <h3 className="mb-4">CopyRight</h3>
            </div> */}
          {/* Book Cover */}
          <div className="h-[18rem] w-[18rem] md:w-[800px] md:h-[800px] rounded flex justify-center items-center relative mx-auto mt-8">
            <div
              className="absolute h-full w-full grayscale rounded"
              style={{
                backgroundImage: `url('${storyPhoto ? storyPhoto : "/pattern.png"}')`,
                backgroundSize: "cover",
              }}
            ></div>
            <div
              className="h-[16rem] w-[13rem] pt-[1rem] md:w-[650px] md:h-[700px] rounded flex justify-center  md:pt-[2rem]"
              style={{
                backgroundImage: `url('${"/texture.png"}')`,
                backgroundSize: "cover",
                zIndex: 1,
              }}
            >
              <div
                className="h-[11rem] w-[11rem] md:w-[590px] md:h-[550px] rounded flex justify-center items-center relative"
                style={{
                  backgroundImage: `url('${storyPhoto ? storyPhoto : "/pattern.png"}')`,
                  backgroundSize: "cover",
                }}
              >
                <div className="relative flex flex-col items-center top-[120px] md:top-[21rem] md:gap-2">
                  <h1 className="text-base  md:text-4xl text-center font-yuji-boku">{bookData?.title}</h1>
                  <h1 className="font-poppins text-xs md:text-lg text-gray-600">
                    by {user?.firstName} {user?.lastName}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div
            className="h-[18rem] w-[18rem]  md:w-[800px] md:h-[800px] flex flex-col justify-around rounded-lg mx-auto py-3 bg-gray-200"
            style={{
              backgroundImage: `url("/paper.jpg")`,
              backgroundSize: "cover",
            }}
          >
            <div className="flex flex-col justify-center items-center p-6 md:p-16  gap-8 md:gap-12">
              <h3 className="text-lg md:text-4xl bold font-yuji-boku">{bookData?.title}</h3>
              <h3 className="text-[10px] md:text-2xl text-justify text-black">
                <p className="leading-[12px] md:leading-8 font-poppins mb-2 md:mb-8">
                  All rights reserved. No part of the publication may be reproduced, stored in or introduced
                  into a retrieval system, or trasmitted, in any form, or by any means (electronic, mechanica,
                  photocopying, recording or otherwise) without the prior written permission of the author.
                </p>
                <p className="leading-[12px] md:leading-8 font-poppins">
                  Any person who does any unauthorized act in relation to this publication may be liable to
                  criminal prosecution ad civil claims for damages. Text and illustrations copyright &#169;{" "}
                  {user?.firstName} {user?.lastName} {getCurrentYear()} Moral rights asserted.
                </p>
              </h3>
            </div>
            <div className="w-full flex flex-col   items-center gap-1">
              <div className="flex items-baseline gap-1">
                <img className="h-3 md:h-6 w-auto" src={Logo} alt="Build My Story" />
              </div>
            </div>
          </div>
          {sortedPages().map(([pageKey, pageDetails], index) => (
            <React.Fragment key={index}>
              <div
                className="h-[18rem] w-[18rem] md:w-[800px] md:h-[800px] mx-auto pt-8 flex flex-col   justify-between rounded-lg "
                style={{
                  backgroundImage: `url("/paper.jpg")`,
                  backgroundSize: "cover",
                }}
              >
                <h3
                  // onClick={() => handleOpenModal(pageKey)}
                  className={`h-[18rem] w-[18rem]  md:h-[800px] md:w-[800px]  flex  p-[2rem] text-justify  justify-center font-bold font-poppins overflow-auto ${
                    bookData?.childAge === AGE_0_2
                      ? "text-5xl md:px-[8rem] md:py-[18rem]"
                      : bookData?.childAge === AGE_3_7
                      ? "text-3xl md:px-[6rem] md:py-[14rem]"
                      : bookData?.childAge === AGE_8_12
                      ? "text-2xl md:px-[4rem] md:py-[10rem]"
                      : "text-xl"
                  }`}
                >
                  {pageDetails.text}
                </h3>
                <TextModal
                  openDialog={isModalOpen}
                  handelClose={handleCloseModal}
                  fullText={pageDetails?.text}
                  pageDetails={selectedPageDetails}
                />
                <div className="page-number mb-2 w-full flex justify-center">
                  <div
                    className="bg-storyblue w-12 h-6 flex justify-center  rounded-full text-white text-base"
                    style={{ fontFamily: "system-ui" }}
                  >
                    {index * 2 + 1}/{totalPages - 1}
                  </div>
                </div>
              </div>
              <div
                className="w-[18rem] h-[18rem] md:w-[800px] md:h-[800px] rounded-lg mx-auto flex flex-col justify-end"
                style={{
                  backgroundImage: `url('${
                    pageDetails.imageURLwebp || pageDetails.imageURL || "/pattern.png"
                  }')`,
                  backgroundSize: "contain",
                }}
              >
                <div className="page-number mb-2 w-full flex justify-center">
                  <div className="bg-storyblue w-12 flex justify-center  rounded-full text-white">
                    {index * 2 + 2}/{totalPages - 1}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
          {/* Back Cover last Blank Page */}
          <div
            className="w-[18rem] h-[18rem] md:w-[800px] md:h-[800px] flex flex-col justify-between mx-auto  py-4 px-5 items-center rounded font-serif"
            style={{
              backgroundImage: `url("/texture.png")`,
              backgroundSize: "cover",
            }}
          >
            <div className="flex flex-col items-center  gap-6 p-5 md:p-16   md:gap-16">
              <h3 className="text-xl md:text-4xl bold font-yuji-boku">{bookData?.title}</h3>
              <h3 className="text-sm md:text-2xl  md:leading-12  font-poppins text-black text-justify">
                {bookData?.summary?.length > 400
                  ? bookData?.summary.slice(0, 400) + "..."
                  : bookData?.summary}
              </h3>
            </div>
            <div className="w-full flex flex-col justify-center items-center gap-2">
              <img className="h-6 md:h-10 w-auto" src={LogoBlack} alt="Build My Story" />
              <h3>www.buildmystory.ai</h3>
              {/* <FontAwesomeIcon icon={faRegistered} style={{ color: "#062457" }} />  */}
              {/* <Barcode value={bookData?.barCode} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BookPage;
