import Spinner from "../Spinner/Spinner";
import Loader from "./Loader";

const SubmitButton = ({ title, loader, size = "w-full", marginTop = "mt-10", cancelOperation=null, disable=false }) => {
  // console.log("disable", disable);
  return (
    <div className="flex gap-3 flex-wrap md:flex-nowrap">
      {cancelOperation && (
        <button
          type="button"
          className={`${size} mt-2 md:${marginTop} flex justify-center bg-storygreen text-white hover:text-white font-bold rounded-md text-base p-2`}
          onClick={() => cancelOperation(false)}
        >
          Close
        </button>
      )}
      <button
        type="submit"
        className={`${
          disable ? "opacity-50" : ""
        }  ${size} mt-2 md:${marginTop} flex justify-center gap-2 bg-storyblue text-white font-bold rounded-md text-base h-11 items-center`}
        disabled={loader || disable}
      >
        {title}
        <Loader loader={loader} />
      </button>
    </div>
  );
};

export default SubmitButton;
