import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubmitButton from "../../../components/Common/SubmitButton";
import { callAPI } from "../../../services/apiService";
import { Toast } from "../../../components/Common/Toast";
import { useFormik } from "formik";
import * as Yup from "yup";

export const CreditForm = ({
  showCreditForm,
  setShowCreditForm,
  getAllUsers,
  getUserBooks,
  creditDetails,
  book = null,
}) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentCredits, setCurrentCredits] = useState(0);

  const validationSchema = Yup.object().shape({
    newCredits: Yup.number().required("New Image Credits are required"),
  });

  //  useEffect(() => {
  //   console.log("useeffect called",showCreditForm)
  //    if (showCreditForm === false) formik.resetForm();
  //  }, [showCreditForm]);

  const formik = useFormik({
    initialValues: {
      newCredits: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async (e) => {
    let title = "Image";
    if (!book) title = "Story";
    try {
      setLoader(true);
      const response = await callAPI("admin/topup-credits", {
        email: showCreditForm,
        bookId: book ? book?.id : null,
        credits: formik.values.newCredits,
      });
      if (response?.error) Toast(response?.error, true);
      else {
        Toast(`${title} Credits Updated Successfully`);
        getAllUsers();
        getUserBooks(showCreditForm);
        setShowCreditForm(false);
      }
      setLoader(false);
      formik.resetForm();
    } catch {
      Toast(`Error ${title} Credits Topup`, true);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (creditDetails) setCurrentCredits(creditDetails);
  }, [creditDetails]);

  return (
    <div className="bg-storytan mx-auto">
      <div className="max-w-7xl mx-auto min-h-screen">
        <h1 className="text-storyblue text-2xl font-bold text-start">
          {book ? "Image Credits Topup" : "Story Credits Topup"}
        </h1>
        <form
          id="form-setting"
          name="form-setting"
          className="mt-2 text-start"
          onSubmit={formik.handleSubmit}
        >
          {book && (
            <h1 className="text-storyblue text-xl font-bold text-start mt-4 mb-4">
              Book: {book?.title || book?.bookId}
            </h1>
          )}
          <label>
            Current {book ? "Image" : "Story"} Credits: {currentCredits}{" "}
          </label>
          <input
            type="text"
            name="newCredits"
            value={formik.values.newCredits}
            onChange={formik.handleChange}
            placeholder="Topup Credits"
            className="mt-4 p-2 w-full rounded-md"
            autoFocus
          />
          <div className="text-red-600 mt-1">{formik.errors.newCredits}</div>
          <SubmitButton title="Save" loader={loader} cancelOperation={setShowCreditForm} />
        </form>
      </div>
    </div>
  );
};
